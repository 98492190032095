import * as d3 from 'd3';
import { textwrap } from 'd3-textwrap';
import { LinkText, NodeDisplayed } from '../FunctionOSTyped';
import { ReturnValueLink } from '../import/OpenSankey';
// Function used only in unitary sankey
// It return the value of links formated
// By default it in percent compared to the sum of link (input or output)
// If the unitary node is a sector and we chose some links as reference then the value is normalised to the sum of selected link
// (Exemple : we select 1 link of value 10 then all link value are noramlised by 1/10  )
export const SankeyDev_link_text = (data, d, GetLinkValue, t) => {
    const data_dev = data;
    if (data.style_link[d.style].unit_link_value_display == undefined || data.style_link[d.style].unit_link_value_display == 'value') {
        return LinkText(data, d, GetLinkValue, t);
    }
    // if (data_dev.unitary_node.length==0) {
    //   return LinkText(data, d, GetLinkValue, t)
    // }
    //const k_n_u = data_dev.unitary_node.filter(kn => kn === d.idTarget || kn === d.idSource)[0]
    const link_ref = Object.values(data_dev.links).filter(l => l.is_ref_value).length > 0; //[]//Object.keys(data_dev.process_transfo_ref).filter(k => k === k_n_u && data_dev.process_transfo_ref[k].length > 0).map(k => k)
    const link_unit = ReturnValueLink(data, d, 'label_unit');
    if (GetLinkValue === undefined) {
        return;
    }
    const the_link_value = GetLinkValue(data, d.idLink);
    // Si le flux n'est pas relié à un noeud unitaire alors sa valeur est de 100%
    if (data.style_link[d.style].unit_link_value_display === 'percent' ||
        (data.style_link[d.style].unit_link_value_display === 'normalize' && !link_ref)) {
        // Si unit_link_value_display est à 'percent'
        // ou bien qu'il est à 'normalize' mais qu'il ne remplie pas les critères pour êtres un process de transformation
        // alors sa valeur est en % par rapport à la somme des flux entrant ou sortant
        let unitary_node;
        let is_source;
        if (data_dev.nodes[d.idSource].inputLinksId.length === 0) {
            unitary_node = data_dev.nodes[d.idTarget];
            is_source = false;
        }
        else {
            unitary_node = data_dev.nodes[d.idSource];
            is_source = true;
        }
        // Calcul le total des flux entrants entre eux et de même pour les sortants
        const total_io = calc_total_input_output(unitary_node, data_dev, GetLinkValue);
        if (data.show_structure === 'structure') {
            return;
        }
        if (data.show_structure === 'data') {
            if (the_link_value.extension.data_value) {
                return the_link_value.extension.data_value;
            }
            else {
                return;
            }
        }
        if (!isNaN(the_link_value.value)) {
            // Return value of link in %
            // If the value has decimal, then fix it to 2 decimal
            const val = is_source ? ((the_link_value.value / total_io[1]) * 100) : ((the_link_value.value / total_io[0]) * 100);
            return (Number.isInteger(val) ? Math.round(val) : (val).toFixed(2)) + ' %';
        }
        else {
            return '0 %';
        }
    }
    else if (data.style_link['SankeyUnitaryInputLinkStyle'].unit_link_value_display === 'normalize' && link_ref) {
        // Si le flux est relié à un noeud unitaire et que ce noeud est un secteur
        // alors 1 ou + flux peuvent être des références et sont utilisés pour normalisé les valeurs
        // Les flux sont normalisé selont la somme des flux de référence
        let sum = 0;
        Object.values(data_dev.links).filter(l => l.is_ref_value).forEach(l => {
            sum += GetLinkValue(data, l.idLink).value;
        });
        const part_value = (the_link_value.value / sum);
        const formated_value = (Number.isInteger(part_value) ? Math.round(part_value) : (part_value).toFixed(3));
        return formated_value + link_unit;
    }
    else if (data.style_link['SankeyUnitaryInputLinkStyle'].unit_link_value_display === 'value') {
        // le noeud unitaire est un secteur mais qu'il n'a pas de flux de référence ou qu'il n'ai pas l'étiquette 'Type de noeud'
        // alors il les affiche normalement
        return LinkText(data, d, GetLinkValue, t);
    }
};
const calc_total_input_output = (n, data, GetLinkValue) => {
    const filtered_input_links = n.inputLinksId.filter(lid => {
        const node_to_test = data.nodes[data.links[lid].idSource];
        return NodeDisplayed(data, node_to_test);
    }).map(id => id);
    let total_input = 0;
    for (const i in filtered_input_links) {
        const val = GetLinkValue(data, filtered_input_links[i]).value;
        total_input += (!isNaN(val) && val !== '' ? val : 0);
    }
    const filtered_output_links = n.outputLinksId.filter(lid => {
        const node_to_test = data.nodes[data.links[lid].idTarget];
        return NodeDisplayed(data, node_to_test);
    }).map(id => id);
    let total_output = 0;
    for (const i in filtered_output_links) {
        const val = GetLinkValue(data, filtered_output_links[i]).value;
        total_output += (!isNaN(val) && val !== '' ? val : 0);
    }
    return [total_input, total_output];
};
// Function that add some information in the legend about how we display links values :
// - Either with the raw value
// - Either as a percent comparing to the sum of input or output links value
// - Either as a value normalized by the value of one or more link linked the the unitary node
export const SankeyDev_legend_elements = (t, data) => {
    var _a;
    const dev_data = data;
    const wrap = textwrap()
        .bounds({ height: 100, width: data.legend_width - 40 })
        .method('tspans');
    const g_legend = d3.select(' .opensankey #g_legend g');
    let shift_y = 0;
    shift_y += data.legend_police + 2;
    g_legend.append('text')
        .attr('transform', 'translate(0, ' + shift_y + ' )')
        .attr('style', 'font-style:italic;font-size:' + (data.legend_police) + 'px')
        .text(() => {
        if (data.style_link['SankeyUnitaryInputLinkStyle'].unit_link_value_display === 'value') {
            return t('view.legend_unit_sankey_values_links');
        }
        else if (data.style_link['SankeyUnitaryInputLinkStyle'].unit_link_value_display === 'percent') {
            return t('view.legend_unit_sankey_percent_links');
        }
        else if (data.style_link['SankeyUnitaryInputLinkStyle'].unit_link_value_display === 'normalize') {
            return t('view.legend_unit_sankey_normalize_links');
        }
        return '';
    });
    shift_y += data.legend_police + 2;
    // if (data.style_link['SankeyUnitaryInputLinkStyle'].unit_link_value_display === 'normalize') {
    //   Object.entries(dev_data.links).filter(l => l[1].is_ref_value).forEach(d => {
    //     g_legend.append('text')
    //       .attr('transform', 'translate(0, ' + shift_y + ' )')
    //       .text(data.nodes[d[0]].name + ' : ' + d[1].map(lid => data.nodes[data.links[lid].idSource].name + '-->' + data.nodes[data.links[lid].idTarget].name).join(', '))
    //       .call(wrap)
    //     shift_y += data.legend_police + 2
    //   })
    // }
    let h = (_a = document.getElementById('g_legend')) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().height;
    h = h ? h : 50;
    d3.select('#g_legend .drag_zone_leg').attr('height', h);
};
