import React, { useState } from 'react';
import { diff as getDiff, applyChange } from 'deep-diff';
import { AssignLinkLocalAttribute, AssignNodeLocalAttribute, ComputeTotalOffsets, FindMaxLinkValue, GetLinkValue, NodeDisplayed, ReturnValueNode, TestLinkValue, ReturnValueLink, DeleteNode, DeleteLink, AddDataTags, DefaultNode } from '../configmenus/SankeyUtils';
import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text } from '@chakra-ui/react';
export const reorganize_inputLinksId = (data, node, input, output, nodes, links) => {
    if (input) {
        reorganize_node_inputLinksId(data, node, nodes, links);
    }
    if (output) {
        reorganize_node_outputLinksId(data, node, nodes, links);
    }
};
/**
 * Synchronise input / ouput links ids of nodes
 * with informations from links
 *
 * @param {SankeyNode} nodes
 * @param {SankeyLink} links
 */
export const compute_default_input_outputLinksId = (nodes, links) => {
    // Reset lists of input and ouput links for each nodes
    Object.values(nodes).forEach(n => {
        n.inputLinksId = [];
        n.outputLinksId = [];
    });
    // Rewrite lists of input and ouput links for each nodes
    // from links information
    Object.values(links).forEach(link => {
        nodes[link.idTarget].inputLinksId.push(link.idLink);
        nodes[link.idSource].outputLinksId.push(link.idLink);
    });
};
export const apply_input_outputLinksId = (ref_nodes, data) => {
    Object.values(ref_nodes).forEach((ref_node) => {
        const node = data.nodes[ref_node.idNode];
        if (!node) {
            return;
        }
        const new_inputLinksId = [];
        ref_node.inputLinksId.forEach((idLink) => {
            const ref_link = data.links[idLink];
            if (ref_link === undefined) {
                return;
            }
            new_inputLinksId.push(idLink);
        });
        node.inputLinksId = new_inputLinksId;
        const new_outputLinksId = [];
        ref_node.outputLinksId.forEach((idLink) => {
            const ref_link = data.links[idLink];
            if (ref_link === undefined) {
                return;
            }
            new_outputLinksId.push(idLink);
        });
        node.outputLinksId = new_outputLinksId;
    });
};
/**
 * Explore all node's branches to compute all their nodes horizontal index
 *
 * @param {SankeyNode} node Node to start exploring from
 * @param {number} starting_index
 * @param {string[]} visible_nodes_ids List of nodes (by their id) that are currently visible on Sankey diagram
 * @param {string[]} visited_nodes_ids List of nodes (by their id) that have been visited. Helps to find recycling flux
 * @param {string[]} recycling_links_ids Links (by their id) that are detected as recycling link
 * @param {object} horizontal_indexes_per_nodes_ids Current horizontal index for given node id
 * @param {object} links
 * @param {object} nodes
 */
export const computeHorizontalIndex = (node, starting_index, visible_nodes_ids, visited_nodes_ids, recycling_links_ids, horizontal_indexes_per_nodes_ids, links, nodes) => {
    // Update node index
    if (!horizontal_indexes_per_nodes_ids[node.idNode]) {
        horizontal_indexes_per_nodes_ids[node.idNode] = starting_index;
        node.u = starting_index;
    }
    else {
        if (starting_index > horizontal_indexes_per_nodes_ids[node.idNode]) {
            horizontal_indexes_per_nodes_ids[node.idNode] = starting_index;
            node.u = starting_index;
        }
    }
    // From current node, use output links to
    // recurse on following node
    node
        .outputLinksId
        .filter(linkId => 
    // Computes only for link to visible nodes
    // and not for nodes related to recyling flux
    (visible_nodes_ids.includes(links[linkId].idTarget) &&
        !recycling_links_ids.includes(linkId)))
        .forEach(linkId => {
        // Next node to recurse on
        const next_node = nodes[links[linkId].idTarget];
        // But first we check if next node has not been already visited
        if (!visited_nodes_ids.includes(next_node.idNode)) {
            // Recursive calling
            computeHorizontalIndex(next_node, starting_index + 1, visible_nodes_ids, [...visited_nodes_ids, node.idNode], recycling_links_ids, horizontal_indexes_per_nodes_ids, links, nodes);
        }
        else {
            // If next node has already been visited then this means
            // that link between current node and next node
            // is a recycling flux
            //
            // To illustrate :
            // -> This example count as recycling flux :
            //    N0 - N11 - N21 - N3
            //       \ N12 - N22 -
            //          |         |
            //           ---------
            // -> But not this one :
            //    N0 - N11 - N21 - N3
            //       \ N12 - N22 \
            //          |         |
            //           ---------
            recycling_links_ids.push(linkId);
        }
    });
};
/**
 * Recompte index for link taggued as recyling links
 * We need to recompute positionning of next_node,
 * because of recycling link, its position can be all wrong
 * -> exemple
 *
 *     N0 - N11 - N21 - N3
 *       \     \
 *        N12 - N22 \
 *         |         |
 *          ---------
 *
 *    So we got N0->N11->N22->N12->N22 stop
 *               0   1    2    3
 *    And the link N12->N22 will be considered as
 *    recycled link and we will get
 *
 *      N0 - N11 - N21 - N3
 *        \      \
 *         \       N22
 *          \    /
 *           \   -------------
 *            \              |
 *             --------- N12 -
 *    So we need to recompute N12 index
 *
 * @param {SankeyLink} link Link that has been previoulsy taggued ass possible recyling link
 * @param {string[]} visible_nodes_ids List of nodes (by their id) that are currently visible on Sankey diagram
 * @param {string[]} recycling_links_ids Links (by their id) that are detected as recycling link
 * @param {object} horizontal_indexes_per_nodes_ids Current index for given node id
 * @param {object} links
 * @param {object} nodes
 */
export const compute_recycling_horizontal_index = (link, visible_nodes_ids, recycling_links_ids, horizontal_indexes_per_nodes_ids, links, nodes) => {
    // Get id for source and target
    const target_node_id = link.idTarget;
    const source_node_id = link.idSource;
    // Compute only if horizontal indexes for source >= horizontal index for target
    // which can not be the case if these nodes' indexes have been reprocessed
    // by this same function
    if (horizontal_indexes_per_nodes_ids[source_node_id] >=
        horizontal_indexes_per_nodes_ids[target_node_id]) {
        // For source node, check if there is a gap
        // between its horizontal index and all the horizontal
        // indexes of nodes that are sources of its own inputs links
        const indexes_before_source_node = [];
        let min_index = -1;
        nodes[source_node_id]
            .inputLinksId
            .forEach(input_link_id => {
            const index = horizontal_indexes_per_nodes_ids[links[input_link_id].idSource];
            if (min_index >= 0) {
                if (index < min_index) {
                    min_index = index;
                }
            }
            else {
                min_index = index;
            }
            indexes_before_source_node.push(index);
        });
        // If there is a gap, we recompute source node horizontal indexing
        const horizontal_index_of_source_node = horizontal_indexes_per_nodes_ids[source_node_id]; // memorize value for loop
        for (let index = min_index + 1; index < horizontal_index_of_source_node; index++) {
            // Gap check here
            if (!indexes_before_source_node.includes(index)) {
                horizontal_indexes_per_nodes_ids[source_node_id] = index;
                // TODO faut un forçage des indexs à suivre.
                computeHorizontalIndex(nodes[source_node_id], index, visible_nodes_ids, [], recycling_links_ids, horizontal_indexes_per_nodes_ids, links, nodes);
                break;
            }
        }
    }
};
export const arrangeNodes = (data) => {
    Object.values(data.nodes).forEach(node => {
        if (!NodeDisplayed(data, node) || ReturnValueNode(data, node, 'position') === 'relative') {
            return;
        }
        const x = Math.round(node.x / data.grid_square_size) * data.grid_square_size;
        const y = Math.round(node.y / data.grid_square_size) * data.grid_square_size;
        node.x = x;
        node.y = y;
    });
};
/**
 * Calcul de la hauteur difference'un noeud
 *
 * @param {SankeyNode} node Node to compute height from
 * @param {SankeyData} data
 * @param {{ [node_id: string]: SankeyNode }} display_nodes Visible nodes
 * @param {Function} inv_scale
 * @param {Function} scale
 * @param {Function} GetLinkValue
 */
export const nodeHeight = (node, applicationData, GetLinkValue) => {
    const { data } = applicationData;
    const res = ComputeTotalOffsets(node, applicationData, TestLinkValue, undefined, GetLinkValue);
    const [total_offset_height_left, total_offset_height_right] = res;
    let node_size_s_height = Math.max(total_offset_height_left, total_offset_height_right);
    node_size_s_height = Math.max(node_size_s_height, +ReturnValueNode(data, node, 'node_height'));
    //Hauteur des noeuds
    if ((res[0] === 0) &&
        (res[1] === 0) &&
        (res[2] === 0) &&
        (res[3] === 0) || data.show_structure == 'structure') {
        // Hauteur des noeuds
        // return data.node_height
        return ReturnValueNode(data, node, 'node_height');
    }
    return node_size_s_height;
};
/**
 * Calcul de la hauteur difference'un noeud
 *
 * @param {SankeyNode} node Node to compute height from
 * @param {SankeyData} data
 * @param {{ [node_id: string]: SankeyNode }} display_nodes Visible nodes
 * @param {Function} inv_scale
 * @param {Function} scale
 * @param {Function} GetLinkValue
 */
export const nodeWidth = (node, applicationData, GetLinkValue) => {
    const { data } = applicationData;
    const res = ComputeTotalOffsets(node, applicationData, TestLinkValue, undefined, GetLinkValue);
    const [, , total_offset_width_top, total_offset_width_bottom] = res;
    let width = Math.max(total_offset_width_top, total_offset_width_bottom);
    width = Math.max(width, +ReturnValueNode(data, node, 'node_width'));
    //Hauteur des noeuds
    if ((res[0] === 0) &&
        (res[1] === 0) &&
        (res[2] === 0) &&
        (res[3] === 0) || data.show_structure == 'structure') {
        // Hauteur des noeuds
        // return data.node_height
        return ReturnValueNode(data, node, 'node_width');
    }
    return width;
};
/**
 * Calcul la plus longue branch
 * Determination de la position horiz
 * Determination de la position vericale
 * Post-traitement ecart
 *
 * @param {SankeyData} data Data structure for Sankey
 * @param {number} h_space Horizontal spacing factor
 */
export const ComputeAutoSankey = (applicationData, launched_from_process, sort_vertically = true) => {
    const { data } = applicationData;
    //data.parametric_mode = true
    const display_nodes = Object.keys(data.nodes)
        .filter((key) => NodeDisplayed(data, data.nodes[key]))
        .reduce((obj, key) => {
        return Object.assign(obj, {
            [key]: data.nodes[key]
        });
    }, {});
    applicationData.display_nodes = display_nodes;
    // AssignNodeStyleAttribute(data.style_node['default'],'position','parametric')
    // if ( data.style_node['NodeSectorStyle'] ) {
    //   AssignNodeStyleAttribute(data.style_node['NodeSectorStyle'],'position','parametric')
    // }
    // if ( data.style_node['NodeProductStyle'] ) {
    //   AssignNodeStyleAttribute(data.style_node['NodeProductStyle'],'position','parametric')
    // }
    // AssignNodeStyleAttribute(data.style_node['NodeImportStyle'],'position','parametric')
    // AssignNodeStyleAttribute(data.style_node['NodeExportStyle'],'position','parametric')
    const display_links = Object.keys(data.links)
        .filter((key) => data.links[key].idSource in display_nodes && data.links[key].idTarget in display_nodes)
        .reduce((obj, key) => {
        return Object.assign(obj, {
            [key]: data.links[key]
        });
    }, {});
    applicationData.display_links = display_links;
    // Positionning values
    // Calcul de la valeur max des flux
    let max_link_value = 0;
    Object.values(data.links).forEach(link => {
        // We use a function to max value for each link because
        // each link can have multiple values
        max_link_value = FindMaxLinkValue(data, max_link_value, link.value, link);
    });
    max_link_value += 1; // Protection if all values are at 0
    // Get scale from max value
    if (launched_from_process) {
        data.user_scale = data.maximum_flux ? Math.min(data.maximum_flux, max_link_value) : max_link_value;
        data.display_style.filter_label = data.user_scale / 10;
    }
    // Reset input / ouput links id for each node
    compute_default_input_outputLinksId(data.nodes, data.links);
    // Get list of all visible nodes
    //  /!\ the nodes of this list will be the only nodes
    //      that are going to be positionned
    const visible_nodes_ids = Object.values(display_nodes)
        .filter(n => !('Type de noeud' in n.tags) || n.tags['Type de noeud'][0] !== 'echange')
        .map(n => n.idNode);
    // Compute positionning indexes
    const horizontal_indexes_per_nodes_ids = {};
    const possible_recycling_links_ids = [];
    Object.values(visible_nodes_ids)
        .forEach(node_id => {
        const node = data.nodes[node_id];
        if ((node.inputLinksId.length === 0) &&
            (node.outputLinksId.length > 0)) {
            // get current node horizontal index (eg longest branch length)
            const starting_index = 0;
            computeHorizontalIndex(node, starting_index, visible_nodes_ids, [], possible_recycling_links_ids, horizontal_indexes_per_nodes_ids, data.links, data.nodes);
        }
        else {
            // Lone node case
            if ((node.inputLinksId.length === 0) &&
                (node.outputLinksId.length === 0)) {
                horizontal_indexes_per_nodes_ids[node_id] = 0;
            }
        }
    });
    // Double check recycling links
    const checked_recycling_links_ids = [];
    Object.values(possible_recycling_links_ids)
        .forEach(link_id => compute_recycling_horizontal_index(data.links[link_id], visible_nodes_ids, checked_recycling_links_ids, horizontal_indexes_per_nodes_ids, data.links, data.nodes));
    // Use results from previous index computing
    // TODO : maybe possible to speed up here overall computing with getting
    //        max_horizontal_index and nodes_per_horizontal_indexes from another loop
    let max_horizontal_index = 0;
    const nodes_per_horizontal_indexes = {};
    Object.values(visible_nodes_ids).forEach(node_id => {
        // Previously computed index for given node
        const node_index = horizontal_indexes_per_nodes_ids[node_id];
        // Update reversed dict index-> nodes
        if (!nodes_per_horizontal_indexes[node_index]) {
            nodes_per_horizontal_indexes[node_index] = [];
        }
        nodes_per_horizontal_indexes[node_index].push(data.nodes[node_id]);
        // Update max horizontal index
        if (node_index > max_horizontal_index) {
            max_horizontal_index = node_index;
        }
        // Set recycling links
        Object.values(data.nodes[node_id].outputLinksId)
            .forEach(link_id => {
            // Get id for source and target
            const target_node_id = data.links[link_id].idTarget;
            // Compute only if indexes for source >= index for target
            // which can not be the case if these nodes have been reprocessed
            // by this same function
            if (node_index >= horizontal_indexes_per_nodes_ids[target_node_id]) {
                AssignLinkLocalAttribute(data.links[link_id], 'recycling', true);
            }
            else {
                AssignLinkLocalAttribute(data.links[link_id], 'recycling', false);
            }
        });
    });
    // for the node which have no input links they should stick to the next output node and
    // have an horizontal index equal to output node horizontal index minus one
    for (let horizontal_index = 0; horizontal_index <= max_horizontal_index; horizontal_index++) {
        // Pass if no nodes for this horizontal_index
        // TODO : if it is the case -> something was wrong before
        if (!nodes_per_horizontal_indexes[horizontal_index]) {
            continue;
        }
        const to_splice = [];
        nodes_per_horizontal_indexes[horizontal_index].forEach(node => {
            if (node.inputLinksId.length === 0) {
                let min_next_horizontal_index = max_horizontal_index + 1;
                node.outputLinksId.forEach((idLink) => {
                    if (display_nodes[data.links[idLink].idSource] && display_nodes[data.links[idLink].idTarget]) {
                        const target_node = data.nodes[data.links[idLink].idTarget];
                        if (target_node === undefined) {
                            return;
                        }
                        if (horizontal_indexes_per_nodes_ids[target_node.idNode] < horizontal_indexes_per_nodes_ids[node.idNode]) {
                            return;
                        }
                        if (horizontal_indexes_per_nodes_ids[target_node.idNode] < min_next_horizontal_index) {
                            min_next_horizontal_index = horizontal_indexes_per_nodes_ids[target_node.idNode];
                        }
                    }
                });
                if (horizontal_indexes_per_nodes_ids[node.idNode] < min_next_horizontal_index - 1) {
                    to_splice.push(node);
                    // Il semblerait que dans certains cas nodes2horizontal_indices de certains noeuds peuvent devenir négatif
                    // ce qui lors de l'affectation difference'une position x, ceux-ci sont négatif
                    horizontal_indexes_per_nodes_ids[node.idNode] = min_next_horizontal_index - 1;
                    if (!nodes_per_horizontal_indexes[min_next_horizontal_index - 1]) {
                        nodes_per_horizontal_indexes[min_next_horizontal_index - 1] = [];
                    }
                    nodes_per_horizontal_indexes[min_next_horizontal_index - 1].push(node);
                }
            }
        });
        to_splice.forEach(node => nodes_per_horizontal_indexes[horizontal_index].splice(nodes_per_horizontal_indexes[horizontal_index].indexOf(node), 1));
    }
    // Loop on all index "columns"
    let h_left_margin = data.style_node['default'].dx;
    let h_right_margin = data.style_node['default'].dx;
    const height_cumul_per_indexes = [];
    const height_per_nodes_ids = {};
    const node_id_per_hxv_indexes = [];
    let max_height_cumul = 0;
    for (let h_index = 0; h_index <= max_horizontal_index; h_index++) {
        // Pass if no nodes for this index
        if (!nodes_per_horizontal_indexes[h_index]) {
            continue;
        }
        // Loop on nodes from computed horizontal index
        let height_cumul_for_index = 0;
        let max_vertical_index = 0;
        const sortcoef_per_nodes_ids = {};
        const vertical_indexes_per_node_id = {};
        const nodes_ids_per_vertical_index = [];
        nodes_per_horizontal_indexes[h_index]
            .forEach(node => {
            // Node height
            const node_height = nodeHeight(node, applicationData, GetLinkValue);
            vertical_indexes_per_node_id[node.idNode] = max_vertical_index;
            node.v = max_vertical_index;
            // Coef to verticaly sort nodes - highest coef is upper
            // - Empirique : prend en considération taille du neoud et taille du noeud normalisée
            const node_sortcoef = node_height * (0.8 + 0.2 / (node.outputLinksId.length + node.inputLinksId.length));
            // Verticaly sort nodes accordingly to their height
            height_per_nodes_ids[node.idNode] = node_height;
            sortcoef_per_nodes_ids[node.idNode] = node_sortcoef;
            if (node.local) {
                delete node.local.dy;
            }
            nodes_ids_per_vertical_index.push(node.idNode);
            if (sort_vertically && max_vertical_index > 0) {
                // Bubble sort algo
                for (let v_index = max_vertical_index; v_index > 0; v_index--) {
                    // Prev node infos
                    const prev_v_index = v_index - 1;
                    const prev_node_id = nodes_ids_per_vertical_index[prev_v_index];
                    const prev_node_sortcoef = sortcoef_per_nodes_ids[prev_node_id];
                    if (prev_node_sortcoef < node_sortcoef) {
                        // Update referencing for bubble node
                        vertical_indexes_per_node_id[node.idNode] = prev_v_index;
                        nodes_ids_per_vertical_index[prev_v_index] = node.idNode;
                        //node.v = prev_v_index
                        //node.dy = 0
                        // Update referencing for prev node
                        vertical_indexes_per_node_id[prev_node_id] = v_index;
                        nodes_ids_per_vertical_index[v_index] = prev_node_id;
                    }
                    else {
                        break;
                    }
                }
            }
            max_vertical_index += 1;
            // Compute cumulative height for given index
            height_cumul_for_index += node_height;
            // Compute left horizontal margin
            if (h_index == 0) {
                const node_label_width = data.style_node[node.style].label_box_width;
                const needed_margin = data.grid_square_size + node_label_width;
                if (needed_margin > h_left_margin) {
                    h_left_margin = needed_margin;
                }
            }
            // Compute right horizontal margin
            // if (h_index == (max_horizontal_index - cumul_shifting_value)) {
            if (h_index == max_horizontal_index) {
                const node_label_width = data.style_node[node.style].label_box_width;
                const needed_margin = data.grid_square_size + node_label_width;
                if (needed_margin > h_right_margin) {
                    h_right_margin = needed_margin;
                }
            }
        });
        // Get horizontal index that need the most of vertical space
        // with vertical spacing between nodes in account
        height_cumul_for_index += (nodes_per_horizontal_indexes[h_index].length - 1) * (data.style_node['default'].dy);
        if (height_cumul_for_index > max_height_cumul) {
            max_height_cumul = height_cumul_for_index;
        }
        height_cumul_per_indexes.push(height_cumul_for_index);
        // Update global indexing table
        node_id_per_hxv_indexes.push(nodes_ids_per_vertical_index);
    }
    max_horizontal_index = (node_id_per_hxv_indexes.length - 1);
    // Update horizontal and vertical position of nodes
    // compute total height of nodes that belong to the same column,
    // then compute the spaces between them and their positions.
    const v_margin = data.style_node['default'].dy;
    let index_nodes_width = 0;
    for (let horizontal_index = 0; horizontal_index <= max_horizontal_index; horizontal_index++) {
        // Pass if no nodes for this horizontal_index
        // TODO : if it is the case -> something was wrong before
        if (!node_id_per_hxv_indexes[horizontal_index]) {
            continue;
        }
        // Loop on horizontal_index node
        const center_biggest_nodes = (node_id_per_hxv_indexes[horizontal_index].length > 2) && true; // TODO put function arg instead of true
        const h_position_for_index = h_left_margin + horizontal_index * data.style_node['default'].dx + index_nodes_width;
        const v_margin_for_index = v_margin + (max_height_cumul - height_cumul_per_indexes[horizontal_index]) / 2;
        let upper_node_height_and_margin = v_margin_for_index;
        if (center_biggest_nodes === true) {
            // From the bottom to the top : plot node every two index
            let last_index = (node_id_per_hxv_indexes[horizontal_index].length - 1);
            for (let index = last_index; index >= 0; index -= 2) {
                const node_id = node_id_per_hxv_indexes[horizontal_index][index];
                // Node position
                data.nodes[node_id].x = h_position_for_index;
                data.nodes[node_id].y = upper_node_height_and_margin;
                // Update upper margin for next node
                const node_height = height_per_nodes_ids[node_id];
                upper_node_height_and_margin += node_height + v_margin;
                // Update last index
                last_index = index;
            }
            // From the top to the bottom : remaining index
            if (last_index == 0)
                last_index = 1;
            else
                last_index = 0;
            for (let index = last_index; index < node_id_per_hxv_indexes[horizontal_index].length; index += 2) {
                const node_id = node_id_per_hxv_indexes[horizontal_index][index];
                // Node position
                data.nodes[node_id].x = h_position_for_index;
                data.nodes[node_id].y = upper_node_height_and_margin;
                // Update upper margin for next node
                const node_height = height_per_nodes_ids[node_id];
                upper_node_height_and_margin += node_height + v_margin;
            }
        }
        else {
            index_nodes_width = 0;
            node_id_per_hxv_indexes[horizontal_index]
                .forEach(node_id => {
                // Node position
                data.nodes[node_id].x = h_position_for_index;
                data.nodes[node_id].y = upper_node_height_and_margin;
                const node_width = nodeWidth(data.nodes[node_id], applicationData, GetLinkValue);
                if (index_nodes_width < nodeWidth(data.nodes[node_id], applicationData, GetLinkValue)) {
                    index_nodes_width = node_width;
                }
                // Update upper margin for next node
                const node_height = height_per_nodes_ids[node_id];
                upper_node_height_and_margin += node_height + v_margin;
            });
        }
    }
    data.width = h_left_margin + max_horizontal_index * data.style_node['default'].dx + h_right_margin;
    data.height = v_margin * 2 + max_height_cumul;
    // data.style_node['default'].position = 'absolute'
    reorganize_all_input_outputLinksId(data, data.nodes, data.links);
    data.style_node['default'].position = 'parametric';
    ComputeParametrization(applicationData);
    // const columns : {[_:number]:SankeyNode[]} = {}
    // Object.values(display_nodes).filter(n => NodeDisplayed(data, n) && (!('Type de noeud' in n.tags) ||n.tags['Type de noeud'][0] !== 'echange')).forEach(n=>{
    //   if (columns[n.u]) {
    //     columns[n.u].push(n)
    //   } else {
    //     columns[n.u] = [n]
    //   }
    // })
    // Object.values(columns).forEach(column=>{
    //   column.sort((n1,n2)=>n1.y-n2.y)
    //   //Object.values(data.levelTags).forEach( tagGroup=> {
    //   let current_v = 0
    //   column.forEach(n=>current_v = apply_v(applicationData,n,current_v))
    //   //})
    // })
    // reorganize_all_input_outputLinksId(data,data.nodes, data.links)
};
// Fonctions d'individualisation des imports/exports
export const SplitIOrE = (node, importation, data) => {
    //const originalIdNode = node.idNode
    let tmp = [];
    let tmp1;
    let tmp2;
    let tmp3;
    let tmp4;
    let tmp5;
    if (importation) {
        tmp = node.outputLinksId.slice(); // clone
        tmp1 = 'idTarget';
        tmp2 = 'idSource';
        tmp3 = 'outputLinksId';
        tmp4 = 'NodeImportStyle';
        tmp5 = 'LinkImportStyle';
    }
    else {
        tmp = node.inputLinksId.slice(); // clone
        tmp1 = 'idSource';
        tmp2 = 'idTarget';
        tmp3 = 'inputLinksId';
        tmp4 = 'NodeExportStyle';
        tmp5 = 'LinkExportStyle';
    }
    tmp.forEach((idLink) => {
        var _a;
        const input_or_output_link = data.links[idLink];
        const le_nom = node.name + ' - ' + (importation ? 'Importations' : 'Exportations') + ' - ' + data.nodes[input_or_output_link[tmp1]].name;
        let idTrade = data.nodes[input_or_output_link[tmp1]].idNode + '-' + node.idNode + (importation ? 'Importations' : 'Exportations');
        idTrade = idTrade.replaceAll(' ', '');
        const new_node = Object.assign({}, DefaultNode(data), {
            name: le_nom,
            idNode: idTrade,
            tags: JSON.parse(JSON.stringify(node.tags)),
            dimensions: JSON.parse(JSON.stringify(node.dimensions))
        });
        data.nodes[idTrade] = new_node;
        const link = data.links[idLink];
        if (!link) {
            return;
        }
        new_node.style = tmp4;
        link.style = tmp5;
        const extremity_node = data.nodes[link[tmp1]];
        new_node.local = { local_aggregation: (_a = extremity_node.local) === null || _a === void 0 ? void 0 : _a.local_aggregation };
        Object.entries(data.nodes[idTrade]['dimensions']).forEach(dim => {
            if (node.dimensions[dim[0]].parent_name === undefined) {
                return;
            }
            dim[1].parent_name = extremity_node.idNode + '-' + node.dimensions[dim[0]].parent_name + (importation ? 'Importations' : 'Exportations');
        });
        Object.keys(extremity_node.dimensions).forEach(dim_key => {
            new_node.dimensions[dim_key] = JSON.parse(JSON.stringify(extremity_node.dimensions[dim_key]));
            if (extremity_node.dimensions[dim_key].parent_name === undefined) {
                return;
            }
            new_node.dimensions[dim_key].parent_name = extremity_node.dimensions[dim_key].parent_name + '-' + node.idNode + (importation ? 'Importations' : 'Exportations');
        });
        Object.keys(extremity_node.tags).forEach(tag_key => {
            if (tag_key === 'Type de noeud') {
                return;
            }
            const tags = [...extremity_node.tags[tag_key]];
            new_node.tags[tag_key] = JSON.parse(JSON.stringify(tags));
        });
        // Création des flux
        //const new_link = JSON.parse(JSON.stringify(link)) as SankeyLink
        AssignLinkLocalAttribute(link, 'recycling', false);
        //data.links![new_node.idNode] = new_link
        delete data.links[link.idLink];
        link.idLink = new_node.idNode;
        data.links[link.idLink] = link;
        link[tmp2] = new_node.idNode;
        new_node[tmp3].push(new_node.idNode);
    });
};
export const processTrade = (applicationData) => {
    const { data } = applicationData;
    SplitTrade(data);
    ArrangeTrade(applicationData, true);
};
export const SplitTrade = (data) => {
    let { nodes } = data;
    // e.g. "International"
    let trade_nodes = Object.values(nodes).filter(n => n.tags && n.tags['Type de noeud'] && n.tags['Type de noeud'][0].includes('echange'));
    trade_nodes.forEach(node => {
        if (node.outputLinksId.length > 0) { // Imports
            SplitIOrE(node, true, data);
        }
        if (node.inputLinksId.length > 0) { // Exports
            SplitIOrE(node, false, data);
        }
        delete data.nodes[node.idNode];
        // node.inputLinksId.forEach(lId=>data.removed_links![lId] = data.links[lId])
        // node.outputLinksId.forEach(lId=>data.removed_links![lId] = data.links[lId])
    });
    // (data as SankeyData).nodes = Object.assign({},data.initial_nodes,data.additional_nodes)
    // Object.keys(data.removed_nodes!).forEach(idNode=>delete (data as SankeyData).nodes[idNode]);
    // (data as SankeyData).links = Object.assign({},data.initial_links,data.additional_links)
    // Object.keys(data.removed_links!).forEach(idLink=>delete (data as SankeyData).links[idLink])
};
export const RecomputeTrade = (data) => {
    const { nodes, links } = data;
    let import_nodes = Object.values(nodes).filter(n => (('Type de noeud' in n.tags)) && n.tags['Type de noeud'][0] == 'echange' && n.outputLinksId.length > 0);
    let export_nodes = Object.values(nodes).filter(n => (('Type de noeud' in n.tags)) && n.tags['Type de noeud'][0] == 'echange' && n.inputLinksId.length > 0);
    if (import_nodes.length > 0 && import_nodes[0].idNode.includes('-')) {
        const node = JSON.parse(JSON.stringify(import_nodes[0]));
        node.outputLinksId = [];
        node.inputLinksId = [];
        node.tags = { 'Type de noeud': ['echange'] };
        node.idNode = node.idNode.replace('Importations', '');
        node.idNode = node.idNode.split('-')[1];
        node.name = node.idNode;
        nodes[node.idNode] = node;
        import_nodes.forEach(n => {
            let newIdLink = links[n.outputLinksId[0]].idLink;
            const tmp = n.outputLinksId[0].split('---');
            if (tmp.length > 1) {
                newIdLink = tmp[0];
            }
            if (!node.outputLinksId.includes(newIdLink)) {
                node.outputLinksId.push(newIdLink);
            }
            if (tmp.length > 1) {
                const l = JSON.parse(JSON.stringify(links[n.outputLinksId[0]]));
                delete links[n.outputLinksId[0]];
                links[newIdLink] = l;
                l.idLink = newIdLink;
            }
            data.links[newIdLink].idSource = node.idNode;
            delete nodes[n.idNode];
        });
        export_nodes.forEach(n => {
            let newIdLink = links[n.inputLinksId[0]].idLink;
            const tmp = n.inputLinksId[0].split('---');
            if (tmp.length > 1) {
                newIdLink = tmp[1];
            }
            if (!node.inputLinksId.includes(newIdLink)) {
                node.inputLinksId.push(newIdLink);
            }
            if (tmp.length > 1) {
                const l = JSON.parse(JSON.stringify(links[n.inputLinksId[0]]));
                delete links[n.inputLinksId[0]];
                links[newIdLink] = l;
                l.idLink = newIdLink;
            }
            data.links[newIdLink].idTarget = node.idNode;
            delete nodes[n.idNode];
        });
    }
};
export const ArrangeTrade = (applicationData, compute_xy) => {
    const { data } = applicationData;
    const { nodes, links } = data;
    let import_nodes = Object.values(nodes).filter(n => (('Type de noeud' in n.tags)) && n.tags['Type de noeud'][0] == 'echange' && n.outputLinksId.length > 0);
    let export_nodes = Object.values(nodes).filter(n => (('Type de noeud' in n.tags)) && n.tags['Type de noeud'][0] == 'echange' && n.inputLinksId.length > 0);
    let max_vertical_offset = 0;
    const compute_offset = (node) => {
        if (!node.y) {
            return;
        }
        if ('Type de noeud' in node.tags && node.tags['Type de noeud'] && node.tags['Type de noeud'][0] == 'echange' && node.inputLinksId.length > 0) {
            return;
        }
        max_vertical_offset = Math.max(node.y + nodeHeight(node, applicationData, GetLinkValue), max_vertical_offset);
    };
    Object.values(nodes).filter(n => NodeDisplayed(data, n)).forEach(compute_offset);
    max_vertical_offset = max_vertical_offset + 200;
    import_nodes.forEach(node => {
        const output_link = links[node.outputLinksId[0]];
        const target_node = nodes[output_link.idTarget];
        node.u = target_node.u;
        node.v = target_node.v + 2000;
        if (compute_xy) {
            const x = Math.round(target_node.x / data.style_node['default'].dx) * data.style_node['default'].dx - data.style_node['default'].dx;
            node.x = x;
            node.y = 200;
        }
    });
    export_nodes.forEach(node => {
        const input_link = links[node.inputLinksId[0]];
        const source_node = nodes[input_link.idSource];
        node.u = source_node.u;
        node.v = source_node.v + 2000;
        if (compute_xy) {
            const x = Math.round(source_node.x / data.style_node['default'].dx) * data.style_node['default'].dx + data.style_node['default'].dx;
            node.x = x;
            node.y = max_vertical_offset;
        }
    });
};
export const ComputeParametrization = (applicationData) => {
    const { display_nodes, data } = applicationData;
    const columns = {};
    let smaller_x;
    Object.values(display_nodes).forEach(n => {
        if (('Type de noeud' in n.tags) && n.tags['Type de noeud'][0] === 'echange') {
            return;
        }
        if (smaller_x === undefined) {
            smaller_x = n.x;
        }
        if (n.x < smaller_x) {
            smaller_x = n.x;
        }
    });
    Object.values(display_nodes).forEach(n => {
        if (('Type de noeud' in n.tags) && n.tags['Type de noeud'][0] === 'echange') {
            return;
        }
        n.u = Math.floor((n.x - smaller_x / 3) / data.style_node['default'].dx);
        if (!(n.u in columns)) {
            columns[n.u] = [n];
        }
        else {
            columns[n.u].push(n);
        }
    });
    Object.values(applicationData.data.nodes).forEach(n => delete n.v);
    ComputeParametricV(applicationData);
};
export const apply_v = (applicationData, node, current_v
//tagGroup:TagsGroup|undefined
) => {
    const { data } = applicationData;
    const all_nodes = data.nodes; //Object.assign({},data.nodes,data.additional_nodes)
    //node.position = 'parametric'
    if (!node.v) {
        node.v = current_v;
    }
    // if (!tagGroup) {
    //   return current_v+1    
    // }
    //node.y == undefined
    const dim_desagregate_nodes = Object.values(all_nodes).filter(nn => {
        let is_children = false;
        Object.values(data.levelTags).forEach(tagg => {
            if (nn.dimensions[tagg.group_name] && nn.dimensions[tagg.group_name].parent_name === node.idNode) {
                is_children = true;
            }
        });
        return is_children;
    });
    let new_current_v = current_v;
    let current_y = node.y;
    dim_desagregate_nodes.forEach(nn => {
        // parametric
        nn.x = node.x;
        nn.y = current_y;
        current_y = current_y + 20;
        nn.u = node.u;
        //Object.values(data.levelTags).forEach( tagGroup=> {
        new_current_v = apply_v(applicationData, nn, new_current_v);
        //})
    });
    return new_current_v + 1;
};
const apply_v_agregate = (data, node) => {
    const all_nodes = Object.values(data.nodes).filter(n => n.v == undefined);
    const dim_agregate_nodes = all_nodes.filter(nn => {
        let is_parent = false;
        Object.values(data.levelTags).forEach(tagGroup => {
            if (node.dimensions[tagGroup.group_name] && node.dimensions[tagGroup.group_name].parent_name === nn.idNode) {
                is_parent = true;
            }
        });
        return is_parent;
    });
    dim_agregate_nodes.filter(n => n.v == undefined).forEach((nn, i) => {
        nn.x = node.x;
        nn.y = node.y;
        nn.u = node.u;
        nn.v = node.v;
        apply_v_agregate(data, nn);
    });
};
/**
 * Reorganize vertically all input / output position
 * of given links to / from given nodes
 *
 * @param {SankeyData} data Data structure for Sankey
 * @param {object} nodes Dict of node to reorganize
 * @param {object} links Dict of links to reorganize
 */
export const reorganize_all_input_outputLinksId = (data, nodes, links) => {
    Object.values(nodes)
        .forEach(node => {
        reorganize_node_inputLinksId(data, node, nodes, links);
        reorganize_node_outputLinksId(data, node, nodes, links);
    });
};
/**
 * TODO
 *
 * @param {SankeyData} data Data structure for Sankey
 * @param {string} idNode Id of node that we desagregate
 * @param {string} cur_dimension Dimension on which we desagregage node
 * @param {boolean} ComputeAutoSankey Has the function been called from ComputeAutoSankey ?
 */
export const desagregation = (applicationData, link_function, idNode, cur_dimension) => {
    const { data, display_links } = applicationData;
    const node = data.nodes[idNode];
    let nodes_to_recalculate = new Set();
    node.inputLinksId.filter(lid => lid in display_links).forEach(lid => nodes_to_recalculate.add(data.links[lid].idSource));
    node.outputLinksId.filter(lid => lid in display_links).forEach(lid => nodes_to_recalculate.add(data.links[lid].idTarget));
    nodes_to_recalculate.forEach(nid => reorganize_inputLinksId(data, data.nodes[nid], true, true, data.nodes, data.links));
    const dim_desagregate_nodes = getDesagregationNodes(cur_dimension, data, node);
    if (dim_desagregate_nodes.length == 0) {
        return;
    }
    let nodes_heights = 0;
    dim_desagregate_nodes.forEach(n => nodes_heights += nodeHeight(n, applicationData, GetLinkValue));
    let node_above = node;
    let new_y = node.y;
    dim_desagregate_nodes.forEach(n => {
        if (n.local == undefined || n.local == null) {
            n.local = {};
        }
        setLocalAgregation(n, data, true, cur_dimension);
        n.y = new_y;
        node_above = n;
        new_y = node_above.y +
            nodeHeight(node_above, applicationData, link_function.GetLinkValue) +
            + +ReturnValueNode(data, n, 'dy');
        // if (to_compute_auto_sankey) {
        //   if (n.outputLinksId.length === 0) {
        //     AssignNodeLocalAttribute(n,'label_horiz', 'right')
        //     AssignNodeLocalAttribute(n,'label_vert', 'middle')
        //   } else if (n.inputLinksId.length === 0) {
        //     AssignNodeLocalAttribute(n,'label_horiz', 'left')
        //     AssignNodeLocalAttribute(n,'label_vert', 'middle')
        //   } else {
        //     AssignNodeLocalAttribute(n,'label_horiz', 'left')
        //     AssignNodeLocalAttribute(n,'label_vert', 'middle')
        //     AssignNodeLocalAttribute(n,'label_background', true)
        //   }
        // }
    });
    nodes_to_recalculate.forEach(nid => reorganize_inputLinksId(data, data.nodes[nid], true, true, data.nodes, data.links));
    const clicked_node = data.nodes[idNode];
    if (clicked_node.local == undefined || clicked_node.local == null) {
        clicked_node.local = {};
    }
    setLocalAgregation(clicked_node, data, false, cur_dimension);
    const import_nodes = node.inputLinksId.filter(lid => {
        if (!(lid in display_links)) {
            return false;
        }
        const inputNode = data.nodes[data.links[lid].idSource];
        if ('Type de noeud' in inputNode.tags && inputNode.tags['Type de noeud'][0] == 'echange') {
            return true;
        }
        return false;
    }).map(lid => data.nodes[data.links[lid].idSource]);
    import_nodes.forEach(n => desagregation(applicationData, link_function, n.idNode, cur_dimension));
    const export_nodes = node.outputLinksId.filter(lid => {
        if (!(lid in display_links)) {
            return false;
        }
        const outputNode = data.nodes[data.links[lid].idTarget];
        if ('Type de noeud' in outputNode.tags && outputNode.tags['Type de noeud'][0] == 'echange') {
            return true;
        }
        return false;
    }).map(lid => data.nodes[data.links[lid].idTarget]);
    import_nodes.forEach(n => desagregation(applicationData, link_function, n.idNode, cur_dimension));
    export_nodes.forEach(n => desagregation(applicationData, link_function, n.idNode, cur_dimension));
    // if (to_compute_auto_sankey && nb_desagregated > 0) {
    //   agregation(data,dim_desagregate_nodes[0].idNode,cur_dimension)
    // }
};
const hasAggregationLinkToNode = (data, idNodeFather, idNodeCurr, cur_dimension) => {
    if (!data.nodes[idNodeCurr]) {
        return false;
    }
    if (data.nodes[idNodeCurr].dimensions) {
        const curr_dim = data.nodes[idNodeCurr].dimensions[cur_dimension];
        if (curr_dim && curr_dim.parent_name) {
            if (idNodeFather === curr_dim.parent_name) {
                return true;
            }
            else {
                return hasAggregationLinkToNode(data, idNodeFather, curr_dim.parent_name, cur_dimension);
            }
        }
        else {
            return false;
        }
    }
    else {
        return false;
    }
};
/**
 * Function that display the parent node of the node in parameter
 * and hide all descendant of the parent node linked by the dimension cur_dimension
 *
 * @param {SankeyData} data Data structure for Sankey
 * @param {string} idNode Id of node that we aggregate
 * @param {string} cur_dimension Dimension on which we aggregate node
 */
export const agregation = (applicationData, idNode, cur_dimension) => {
    var _a;
    const { data, display_links } = applicationData;
    if (!(cur_dimension in data.nodes[idNode].dimensions)) {
        return;
    }
    const desagregated_node = data.nodes[idNode];
    let nodes_to_recalculate = new Set();
    desagregated_node.inputLinksId.filter(lid => lid in display_links).forEach(lid => nodes_to_recalculate.add(data.links[lid].idSource));
    desagregated_node.outputLinksId.filter(lid => lid in display_links).forEach(lid => nodes_to_recalculate.add(data.links[lid].idTarget));
    const parent_node = data.nodes[(_a = desagregated_node.dimensions[cur_dimension].parent_name) !== null && _a !== void 0 ? _a : ''];
    nodes_to_recalculate.add(parent_node.idNode);
    if (!parent_node) {
        return;
    }
    if (!parent_node.v) {
        parent_node.v = desagregated_node.v;
        parent_node.x = desagregated_node.x;
        parent_node.u = desagregated_node.u;
    }
    const dim_desagregated_nodes = Object.values(data.nodes).filter(n => {
        let is_desagregation = false;
        Object.values(data.levelTags).forEach(tagg => {
            if (is_desagregation) {
                return;
            }
            if (!n.dimensions[tagg.group_name]) {
                return;
            }
            if (n.dimensions[tagg.group_name].parent_name == parent_node.idNode) {
                is_desagregation = true;
            }
        });
        return is_desagregation;
    });
    if (dim_desagregated_nodes.length === 0) {
        return;
    }
    dim_desagregated_nodes.forEach(n => {
        if (n.local == undefined || n.local == null) {
            n.local = {};
        }
        setLocalAgregation(n, data, false, '');
    });
    if (parent_node.local == undefined || parent_node.local == null) {
        parent_node.local = {};
    }
    setLocalAgregation(parent_node, data, true, cur_dimension);
    const import_nodes = desagregated_node.inputLinksId.filter(lid => {
        if (!(lid in display_links)) {
            return false;
        }
        const inputNode = data.nodes[data.links[lid].idSource];
        if ('Type de noeud' in inputNode.tags && inputNode.tags['Type de noeud'][0] == 'echange') {
            return true;
        }
        return false;
    }).map(lid => data.nodes[data.links[lid].idSource]);
    import_nodes.forEach(n => agregation(applicationData, n.idNode, cur_dimension));
    const export_nodes = desagregated_node.outputLinksId.filter(lid => {
        if (!(lid in display_links)) {
            return false;
        }
        const outputNode = data.nodes[data.links[lid].idTarget];
        if ('Type de noeud' in outputNode.tags && outputNode.tags['Type de noeud'][0] == 'echange') {
            return true;
        }
        return false;
    }).map(lid => data.nodes[data.links[lid].idTarget]);
    import_nodes.forEach(n => agregation(applicationData, n.idNode, cur_dimension));
    export_nodes.forEach(n => agregation(applicationData, n.idNode, cur_dimension));
    nodes_to_recalculate.forEach(nid => reorganize_inputLinksId(data, data.nodes[nid], true, true, data.nodes, data.links));
};
export const AgregationModal = ({ applicationData, link_function, agregationRef }) => {
    var _a, _b;
    const { data, set_data } = applicationData;
    const [show_agregation, set_show_agregation] = useState(false);
    const [dim_name, set_dim_name] = useState('');
    const [child_names, set_child_names] = useState([]);
    if (agregationRef.showAgregationRef.current.length == 0) {
        agregationRef.showAgregationRef.current.push([show_agregation, set_show_agregation]);
    }
    const n = agregationRef.agregationNode.current;
    if (!n) {
        return React.createElement(React.Fragment, null);
    }
    const dim_names = [];
    if (agregationRef.isAgregationRef.current) {
        Object.keys(n.dimensions).forEach(dim => {
            if (Object.keys(n.dimensions).length > 1 && dim === 'Primaire') {
                return;
            }
            if (n.dimensions[dim].parent_name) {
                dim_names.push(dim);
            }
        });
        if (dim_name === '') {
            if (dim_names.length === 0) {
                return React.createElement(React.Fragment, null);
            }
            set_dim_name(dim_names[0]);
        }
        return (React.createElement(Modal, { isOpen: show_agregation, onClose: () => {
                set_show_agregation(false);
                set_dim_name('');
            } },
            React.createElement(ModalOverlay, null),
            React.createElement(ModalContent, { maxWidth: 'inherit' },
                React.createElement(ModalHeader, null, "Dimension difference'agr\u00E9gation"),
                React.createElement(ModalCloseButton, null),
                React.createElement(ModalBody, null,
                    React.createElement(Box, null,
                        React.createElement(Select, { onChange: (evt) => set_dim_name(evt.target.value), value: dim_name }, dim_names.map((cur_dir_name, i) => React.createElement("option", { key: i, value: cur_dir_name }, cur_dir_name))),
                        React.createElement(Text, null, dim_name !== '' && data.nodes[(_a = n.dimensions[dim_name].parent_name) !== null && _a !== void 0 ? _a : 0] ? data.nodes[(_b = n.dimensions[dim_name].parent_name) !== null && _b !== void 0 ? _b : 0].name : ''))),
                React.createElement(ModalFooter, null,
                    React.createElement(Button, { variant: "menuconfigpanel_option_button_secondary", onClick: () => {
                            agregation(applicationData, n.idNode, dim_name);
                            set_data(Object.assign({}, data));
                            set_show_agregation(false);
                            set_dim_name('');
                        } }, "Agr\u00E9gation"),
                    React.createElement(Button, { variant: "menuconfigpanel_del_button", onClick: () => {
                            set_show_agregation(false);
                            set_dim_name('');
                        } }, "Annuler")))));
    }
    else {
        Object.values(data.nodes).forEach(n2 => {
            for (const dim in n2.dimensions) {
                if (Object.keys(n2.dimensions).length > 1 && dim === 'Primaire') {
                    continue;
                }
                if (dim in n2.dimensions && n2.dimensions[dim].parent_name == n.idNode) {
                    if (dim_names.indexOf(dim) === -1) {
                        dim_names.push(dim);
                    }
                }
            }
            return false;
        });
        if (dim_name === '') {
            const the_child_names = getDesagregationNodes(dim_names[0], data, n).map(n => n.name);
            set_dim_name(dim_names[0]);
            set_child_names(the_child_names);
        }
        return (React.createElement(Modal, { isOpen: show_agregation, onClose: () => {
                set_show_agregation(false);
                agregationRef.agregationNode.current = undefined;
                set_dim_name('');
            } },
            React.createElement(ModalOverlay, null),
            React.createElement(ModalContent, { maxWidth: 'inherit' },
                React.createElement(ModalHeader, null, "Dimension desagr\u00E9gation"),
                React.createElement(ModalCloseButton, null),
                React.createElement(ModalBody, null,
                    React.createElement(Box, null,
                        React.createElement(Select, { onChange: (evt) => {
                                set_dim_name(evt.target.value);
                                const the_child_names = getDesagregationNodes(evt.target.value, data, n).map(n => n.name);
                                set_child_names(the_child_names);
                            }, value: dim_name }, dim_names.map((cur_dim_name, i) => React.createElement("option", { key: i, value: cur_dim_name }, cur_dim_name))),
                        child_names.map(child_name => React.createElement(Text, null, child_name)))),
                React.createElement(ModalFooter, null,
                    React.createElement(Button, { variant: "menuconfigpanel_option_button_secondary", onClick: () => {
                            desagregation(applicationData, link_function, n.idNode, dim_name);
                            set_data(Object.assign({}, data));
                            set_show_agregation(false);
                            set_dim_name('');
                        } }, "D\u00E9sagr\u00E9gation"),
                    React.createElement(Button, { variant: "menuconfigpanel_del_button", onClick: () => {
                            set_show_agregation(false);
                            set_dim_name('');
                        } }, "Annuler")))));
    }
};
const setLocalAgregation = (n, data, local_aggregation, group_name) => {
    if (!n.local) {
        n.local = {};
    }
    n.local['local_aggregation'] = local_aggregation;
    if (local_aggregation) {
        n.local['local_aggregation_group_name'] = group_name;
    }
    else {
        delete n.local['local_aggregation_group_name'];
    }
};
export const reorganize_node_inputLinksId = (data, node, nodes, links) => {
    // Get list of input links of given node
    const input_links = Object.values(links).filter(link => (link.idTarget === node.idNode));
    // Sorting algorithm between two input links
    input_links.sort((l1, l2) => {
        const n1Id = l1.idSource;
        const n2Id = l2.idSource;
        const l1_recy = ReturnValueLink(data, l1, 'recycling');
        const l2_recy = ReturnValueLink(data, l2, 'recycling');
        const l1_v_s = ReturnValueLink(data, l1, 'vert_shift');
        const l2_v_s = ReturnValueLink(data, l2, 'vert_shift');
        const l1_ori = ReturnValueLink(data, l1, 'orientation');
        const l2_ori = ReturnValueLink(data, l2, 'orientation');
        if (n1Id !== n2Id) {
            const n1 = nodes[n1Id];
            const n2 = nodes[n2Id];
            if (ReturnValueNode(data, n2, 'position') == 'relative') {
                return 1;
            }
            if (ReturnValueNode(data, n1, 'position') == 'relative') {
                return -1;
            }
            if (l1_recy && !l2_recy) {
                if (l1_v_s && l1_v_s < 0) {
                    return -1;
                }
                return 1;
            }
            if (!l1_recy && l2_recy) {
                if (l2_v_s && l2_v_s < 0) {
                    return 1;
                }
                return -1;
            }
            if (l1_ori === 'vh' && l2_ori === 'vh' || l1_ori === 'vv' && l2_ori === 'vv') {
                if (n1 && n2 && n1.x < n2.x) {
                    return -1;
                }
                return 1;
            }
            if (n1 && n2 && n1.y < n2.y) {
                return -1;
            }
            return 1;
        }
        else {
            const n1 = nodes[n1Id];
            if (n1) {
                const output_l1_index = n1.outputLinksId.indexOf(l1.idLink);
                const output_l2_index = n1.outputLinksId.indexOf(l2.idLink);
                const l1_index = input_links.indexOf(l1);
                const l2_index = input_links.indexOf(l1);
                if ((output_l1_index < output_l2_index && l1_index < l2_index) ||
                    (output_l1_index > output_l2_index && l1_index > l2_index)) {
                    return 1;
                }
                return -1;
            }
            else {
                return 1;
            }
        }
    });
    node.inputLinksId = input_links.map(l => l.idLink);
};
/**
 * Reorganize vertically all output links
 * from given node
 *
 * @param {SankeyData} data Data structure for Sankey
 * @param {SankeyNode} node Node on which output links positions must be reorganized
 * @param {object} nodes Dict of node to reorganize
 * @param {object} links Dict of links to reorganize
 */
export const reorganize_node_outputLinksId = (data, node, nodes, links) => {
    // Get list of output links of given node
    const output_links = Object.values(links).filter(l => l.idSource === node.idNode);
    // Sorting algorithm
    output_links.sort((l1, l2) => {
        const n1Id = l1.idTarget;
        const n2Id = l2.idTarget;
        const l1_recy = ReturnValueLink(data, l1, 'recycling');
        const l2_recy = ReturnValueLink(data, l2, 'recycling');
        const l1_v_s = ReturnValueLink(data, l1, 'vert_shift');
        const l2_v_s = ReturnValueLink(data, l2, 'vert_shift');
        const l1_ori = ReturnValueLink(data, l1, 'orientation');
        const l2_ori = ReturnValueLink(data, l2, 'orientation');
        if (n1Id !== n2Id) {
            const n1 = nodes[n1Id];
            const n2 = nodes[n2Id];
            if (ReturnValueNode(data, n2, 'position') == 'relative') {
                return -1;
            }
            if (ReturnValueNode(data, n1, 'position') == 'relative') {
                return 1;
            }
            if (l1_recy && !l2_recy) {
                if (l1_v_s && l1_v_s < 0) {
                    return 1;
                }
                return -1;
            }
            if (!l1_recy && l2_recy) {
                if (l2_v_s && l2_v_s < 0) {
                    return 1;
                }
                return -1;
            }
            if (l1_ori === 'vh' && l2_ori === 'vh' || l1_ori === 'vv' && l2_ori === 'vv') {
                if (n1 && n2 && n1.x < n2.x) {
                    return -1;
                }
                return 1;
            }
            if (n1 && n2 && n1.y < n2.y) {
                return -1;
            }
            return 1;
        }
        else {
            const n1 = nodes[n1Id];
            if (n1) {
                const input_l1_index = n1.inputLinksId.indexOf(l1.idLink);
                const input_l2_index = n1.inputLinksId.indexOf(l2.idLink);
                const l1_index = output_links.indexOf(l1);
                const l2_index = output_links.indexOf(l1);
                if ((input_l1_index < input_l2_index && l1_index < l2_index) ||
                    (input_l1_index > input_l2_index && l1_index > l2_index)) {
                    return 1;
                }
                return -1;
            }
            else {
                return 1;
            }
        }
    });
    node.outputLinksId = output_links.map(l => l.idLink);
};
const normalize_name = (name) => {
    const new_name = name.split('\\n').join('').split(' ').join('');
    return new_name;
};
export const synchronizeNodesandLinksId = (dataModify, dataRef) => {
    //- Stores a mapping between idNode of initial data and layout idNodes
    const idNodesMap = {};
    Object.values(dataModify.nodes).forEach(nodeModify => {
        const nodesRef = Object.values(dataRef.nodes).filter(nodeRef => normalize_name(nodeModify.name) === normalize_name(nodeRef.name));
        if (nodesRef.length === 0) {
            idNodesMap[nodeModify.idNode] = nodeModify.idNode;
            return;
        }
        const nodeRef = nodesRef[0];
        idNodesMap[nodeModify.idNode] = nodeRef.idNode;
    });
    Object.values(dataModify.nodes).forEach(nodeModify => {
        nodeModify.idNode = idNodesMap[nodeModify.idNode];
        Object.keys(nodeModify.dimensions).forEach(dim => {
            var _a;
            if (nodeModify.dimensions[dim].parent_name) {
                nodeModify.dimensions[dim].parent_name = idNodesMap[(_a = nodeModify.dimensions[dim].parent_name) !== null && _a !== void 0 ? _a : 0];
            }
        });
    });
    dataModify.nodes = Object.assign({}, ...Object.values(dataModify.nodes).map(n => ({ [n.idNode]: Object.assign({}, n) })));
    Object.values(dataModify.links).forEach(lModify => {
        lModify.idSource = idNodesMap[lModify.idSource];
        lModify.idTarget = idNodesMap[lModify.idTarget];
    });
    //- Stores a mapping between idLink of initial data and layout idLinks
    const idLinksMap = {};
    const links_with_no_match = [];
    Object.values(dataModify.links).forEach(lModify => {
        const lRef = dataRef.links[lModify.idLink];
        if (!lRef || lRef.idSource !== lModify.idSource || lRef.idTarget !== lModify.idTarget) {
            links_with_no_match.push(lModify);
            return;
        }
        idLinksMap[lModify.idLink] = lRef.idLink;
    });
    links_with_no_match.forEach(l => {
        const linksRef = Object.values(dataRef.links).filter(lRef => l.idSource === lRef.idSource && l.idTarget === lRef.idTarget);
        if (linksRef.length === 0) {
            idLinksMap[l.idLink] = l.idSource + '---' + l.idTarget;
            return;
        }
        const layout_link = linksRef[0];
        idLinksMap[l.idLink] = layout_link.idLink;
    });
    const newLinkZIndex = [];
    dataModify.linkZIndex.forEach(idLink => newLinkZIndex.push(idLinksMap[idLink]));
    dataModify.linkZIndex = newLinkZIndex;
    Object.values(dataModify.links).forEach(l => l.idLink = idLinksMap[l.idLink]);
    dataModify.links = Object.assign({}, ...Object.values(dataModify.links).map(lModify => ({ [lModify.idLink]: Object.assign({}, lModify) })));
    Object.values(dataModify.nodes).forEach(n => {
        const newInputLinksId = [];
        n.inputLinksId.forEach(linkId => newInputLinksId.push(idLinksMap[linkId]));
        n.inputLinksId = newInputLinksId;
        const newOutputLinksId = [];
        n.outputLinksId.forEach(linkId => newOutputLinksId.push(idLinksMap[linkId]));
        n.outputLinksId = newOutputLinksId;
    });
    // compute_default_input_outputLinksId(dataModify.nodes, dataModify.links)
};
export const updateLayout = (data, new_layout, mode, synchronize = false) => {
    if (synchronize) {
        synchronizeNodesandLinksId(data, new_layout);
    }
    if (mode.includes('attrGeneral')) {
        let differences = getDiff(data, new_layout);
        if (differences) {
            const legend_pos = differences.filter(difference => difference.path[0] == 'legend_position');
            legend_pos.forEach((difference) => applyChange(data, {}, difference));
            differences = differences.filter((difference) => (difference.kind === 'E') &&
                (difference.path.length === 1) &&
                (difference.path[0] !== 'current_view'));
            differences.forEach((difference) => applyChange(data, {}, difference));
        }
        const display_style_diff = getDiff(data.display_style, new_layout.display_style);
        if (display_style_diff) {
            display_style_diff.forEach((difference) => applyChange(data.display_style, {}, difference));
        }
        const node_style_differences = getDiff(data.style_node, new_layout.style_node);
        if (node_style_differences) {
            node_style_differences.forEach((difference) => applyChange(data.style_node, {}, difference));
        }
        const link_style_differences = getDiff(data.style_link, new_layout.style_link);
        if (link_style_differences) {
            link_style_differences.forEach((difference) => applyChange(data.style_link, {}, difference));
        }
    }
    if (mode.includes('addNode')) {
        let differences = getDiff(data.nodes, new_layout.nodes);
        if (differences) {
            const nodesId = [];
            differences = differences.filter((difference) => (difference.kind === 'N') &&
                (difference.path.length === 1));
            differences.forEach((difference) => nodesId.push(difference.path[0]));
            differences.forEach((difference) => applyChange(data.nodes, {}, difference));
            nodesId.forEach(nodeId => {
                data.nodes[nodeId].inputLinksId = [];
                data.nodes[nodeId].outputLinksId = [];
            });
        }
    }
    if (mode.includes('removeNode')) {
        let differences = getDiff(data.nodes, new_layout.nodes);
        if (differences) {
            const nodesId = [];
            differences = differences.filter((difference) => (difference.kind === 'D') &&
                (difference.path.length === 1));
            differences.forEach((difference) => nodesId.push(difference.path[0]));
            nodesId.forEach(nodeId => {
                DeleteNode(data, data.nodes[nodeId]);
            });
            differences.forEach((difference) => applyChange(data.nodes, {}, difference));
        }
    }
    if (mode.includes('addFlux')) {
        let differences = getDiff(data.links, JSON.parse(JSON.stringify(new_layout.links)));
        if (differences) {
            const linksId = [];
            differences = differences.filter((difference) => (difference.kind === 'N') &&
                (difference.path.length === 1));
            // added flux have no values
            differences.forEach((difference) => difference.rhs['value'] = { 'value': '', 'display_value': '', 'tags': {}, 'extension': {} });
            differences.forEach((difference) => linksId.push(difference.path[0]));
            differences.forEach((difference) => applyChange(data.links, {}, difference));
            const copyLinksId = [...linksId];
            linksId.forEach(linkId => {
                if (!data.nodes[new_layout.links[linkId].idSource] || !data.nodes[new_layout.links[linkId].idTarget]) {
                    if (copyLinksId.indexOf(linkId) !== -1) {
                        copyLinksId.splice(copyLinksId.indexOf(linkId), 1);
                        delete data.links[linkId];
                    }
                }
            });
            copyLinksId.forEach(linkId => {
                data.nodes[new_layout.links[linkId].idSource].outputLinksId.push(linkId);
                data.nodes[new_layout.links[linkId].idTarget].inputLinksId.push(linkId);
                reorganize_node_inputLinksId(data, data.nodes[new_layout.links[linkId].idTarget], data.nodes, data.links);
                reorganize_node_outputLinksId(data, data.nodes[new_layout.links[linkId].idSource], data.nodes, data.links);
                data.linkZIndex.push(linkId);
            });
            copyLinksId.forEach(linkId => {
                const l = data.links[linkId];
                AddDataTags(Object.values(data.dataTags), l.value, 0);
            });
        }
    }
    if (mode.includes('removeFlux')) {
        let differences = getDiff(data.links, new_layout.links);
        if (differences) {
            const linksId = [];
            differences = differences.filter((difference) => (difference.kind === 'D') &&
                (difference.path.length === 1));
            differences.forEach((difference) => linksId.push(difference.path[0]));
            linksId.forEach(linksId => {
                DeleteLink(data, data.links[linksId]);
            });
            differences.forEach((difference) => applyChange(data.links, {}, difference));
        }
    }
    if (mode.includes('posNode')) {
        let differences = getDiff(data.nodes, new_layout.nodes);
        if (differences) {
            differences = differences.filter((difference) => (difference.kind === 'E') &&
                (['x', 'y', 'u', 'v', 'x_label', 'y_label'].includes(difference.path[1])));
            differences.forEach((difference) => applyChange(data.nodes, {}, difference));
        }
    }
    if (mode.includes('posFlux')) {
        const geometry_attributes = [
            'orientation',
            'left_horiz_shift',
            'right_horiz_shift',
            'vert_shift',
            'curvature',
            'curved',
            'recycling',
            'arrow_size',
            // Geometry link labels
            'x_label',
            'y_label',
            'label_position',
            'orthogonal_label_position',
            'label_on_path'
        ];
        let differences = getDiff(data.links, new_layout.links);
        if (differences) {
            differences = differences.filter((difference) => (difference.kind === 'D' || difference.kind === 'N') &&
                (difference.path.length === 3) &&
                (difference.path[1] === 'local') &&
                (geometry_attributes.includes(difference.path[2])) ||
                (difference.kind === 'E' && geometry_attributes.includes(difference.path[1])));
            differences.forEach((difference) => applyChange(data.links, {}, difference));
        }
        Object.entries(data.nodes).forEach(([key, node]) => {
            const layoutNode = new_layout.nodes[key];
            if (!layoutNode) {
                return;
            }
            const commonInputLinksId = layoutNode.inputLinksId.filter(id => node.inputLinksId.indexOf(id) !== -1);
            let justInNode = node.inputLinksId.filter(id => layoutNode.inputLinksId.indexOf(id) === -1);
            const newInputLinksId = commonInputLinksId.concat(justInNode);
            node.inputLinksId = newInputLinksId;
            const commonOutputLinksId = layoutNode.outputLinksId.filter(id => node.outputLinksId.indexOf(id) !== -1);
            justInNode = node.inputLinksId.filter(id => layoutNode.inputLinksId.indexOf(id) === -1);
            const newOutputLinksId = commonOutputLinksId.concat(justInNode);
            node.outputLinksId = newOutputLinksId;
        });
    }
    if (mode.includes('attrNode')) {
        Object.entries(data.nodes).forEach(([key, node]) => {
            const layoutNode = new_layout.nodes[key];
            if (!layoutNode) {
                return;
            }
            if (!node.local) {
                node.local = {};
            }
            if (!layoutNode.local) {
                layoutNode.local = {};
            }
            const local_aggregation = node.local.local_aggregation;
            const differences = getDiff(node.local, layoutNode.local);
            if (differences) {
                differences.forEach((difference) => applyChange(node.local, {}, difference));
            }
            if (local_aggregation) {
                node.local.local_aggregation = local_aggregation;
            }
            node.style = layoutNode.style;
        });
    }
    if (mode.includes('attrFlux')) {
        Object.entries(data.links).forEach(([key, link]) => {
            const layoutLink = new_layout.links[key];
            if (!layoutLink) {
                return;
            }
            if (!link.local) {
                link.local = {};
            }
            if (!layoutLink.local) {
                layoutLink.local = {};
            }
            const differences = getDiff(link.local, layoutLink.local);
            if (differences) {
                differences.forEach((difference) => applyChange(link.local, {}, difference));
            }
            link.style = layoutLink.style;
        });
    }
    if (mode.includes('Values')) {
        const dataTagsNames = Object.values(data.dataTags).map(tagGroup => tagGroup.group_name);
        const layoutTagsNames = Object.values(new_layout.dataTags).map(tagGroup => tagGroup.group_name);
        if (JSON.stringify(dataTagsNames) === (JSON.stringify(layoutTagsNames))) {
            Object.entries(data.links).forEach(([key, link]) => {
                const layoutLink = new_layout.links[key];
                if (!layoutLink) {
                    return;
                }
                const differences = getDiff(link.value, layoutLink.value);
                if (differences) {
                    differences.forEach((difference) => applyChange(link.value, {}, difference));
                }
            });
            // If values are applied dataTags must be applied also
            const differences = getDiff(data.dataTags, new_layout.dataTags);
            if (differences) {
                differences.forEach((difference) => applyChange(data.dataTags, {}, difference));
            }
        }
    }
    if (mode.includes('tagLevel')) {
        // const differences = getDiff(data.levelTags, new_layout.levelTags)
        // if (differences) {
        //   differences.forEach((difference) => applyChange(data.levelTags, {}, difference))
        // }
        // Finds the corresponding tag group by name and apply the "dynamic" attributes
        // activate, show_legend and selected.
        // Object.values(data.levelTags).forEach(nodeTag=>{
        //   Object.values(new_layout.levelTags).filter(_=>_.group_name === nodeTag.group_name).forEach(_=>{
        //     nodeTag.activated=_.activated
        //     nodeTag.show_legend = _.show_legend
        //     Object.values(nodeTag.tags).forEach(tag=>
        //       Object.values(_.tags).filter(ltag=>ltag.name === tag.name).forEach(ltag=>{
        //         tag.selected = ltag.selected
        //         if (ltag.color) {
        //           tag.color = ltag.color
        //         }
        //       })
        //     )
        //   })
        // })
        // Object.keys(new_layout.levelTags).forEach(tagGroup=>{
        //   if (!(tagGroup in data.levelTags)) {
        //     data.levelTags[tagGroup] = {...new_layout.levelTags[tagGroup]}
        //   }
        //   Object.values(data.nodes).forEach(n=>{
        //     if (!n.tags[tagGroup]) {
        //       n.tags[tagGroup] = []
        //     }
        //     if (new_layout.nodes[n.idNode]?.tags[tagGroup] ?? false) {
        //       n.tags[tagGroup] = [...new Set([...n.tags[tagGroup],...new_layout.nodes[n.idNode].tags[tagGroup]])]
        //     }
        //   })
        // })
        Object.keys(new_layout.levelTags).forEach(tagGroup => {
            Object.values(data.nodes).forEach(n => {
                if (new_layout.nodes[n.idNode] == undefined) {
                    return;
                }
                n.dimensions[tagGroup] = new_layout.nodes[n.idNode].dimensions[tagGroup];
            });
        });
    }
    if (mode.includes('tagNode')) {
        // Finds the corresponding tag group by name and apply the "dynamic" attributes
        // activate, show_legend and selected.
        Object.values(data.nodeTags).forEach(nodeTag => {
            Object.values(new_layout.nodeTags).filter(_ => _.group_name === nodeTag.group_name).forEach(_ => {
                nodeTag.activated = _.activated;
                nodeTag.show_legend = _.show_legend;
                Object.values(nodeTag.tags).forEach(tag => Object.values(_.tags).filter(ltag => ltag.name === tag.name).forEach(ltag => {
                    tag.selected = ltag.selected;
                    if (ltag.color) {
                        tag.color = ltag.color;
                    }
                }));
            });
        });
        // Sets the selected colormap
        if (new_layout.nodeTags[new_layout.nodesColorMap] !== undefined) {
            const color_map_name = new_layout.nodeTags[new_layout.nodesColorMap].group_name;
            const layout_groups = Object.values(data.nodeTags).filter(tagg => tagg.group_name == color_map_name);
            if (layout_groups.length > 0) {
                data.nodesColorMap = layout_groups[0].group_name;
                Object.values(data.nodes).forEach(el => {
                    el.colorParameter = 'groupTag';
                    el.colorTag = data.nodesColorMap;
                });
            }
        }
        // Object.keys(new_layout.nodeTags).forEach(tagGroup=>{
        //   if (!(tagGroup in data.nodeTags)) {
        //     data.nodeTags[tagGroup] = {...new_layout.nodeTags[tagGroup]}
        //   }
        //   Object.values(data.nodes).forEach(n=>{
        //     if (!n.tags[tagGroup]) {
        //       n.tags[tagGroup] = []
        //     }
        //     if (new_layout.nodes[n.idNode]?.tags[tagGroup] ?? false) {
        //       n.tags[tagGroup] = [...new Set([...n.tags[tagGroup],...new_layout.nodes[n.idNode].tags[tagGroup]])]
        //     }
        //   })
        // })
    }
    if (mode.includes('tagFlux')) {
        // Finds the corresponding tag group by name and apply the "dynamic" attributes
        // activate, show_legend and selected.
        Object.values(data.fluxTags).forEach(fluxTag => {
            Object.values(new_layout.fluxTags).filter(_ => _.group_name === fluxTag.group_name).forEach(_ => {
                fluxTag.activated = _.activated;
                fluxTag.show_legend = _.show_legend;
                Object.values(fluxTag.tags).forEach(tag => Object.values(_.tags).filter(ltag => ltag.name === tag.name).forEach(ltag => {
                    tag.selected = ltag.selected;
                }));
            });
        });
        // Sets the selected colormap
        if (new_layout.fluxTags[new_layout.linksColorMap] !== undefined) {
            const color_map_name = new_layout.fluxTags[new_layout.linksColorMap].group_name;
            const layout_groups = Object.values(data.fluxTags).filter(tagg => tagg.group_name == color_map_name);
            if (layout_groups.length > 0) {
                data.linksColorMap = layout_groups[0].group_name;
                Object.values(data.links).forEach(el => {
                    el.colorTag = data.linksColorMap;
                });
            }
        }
        Object.keys(new_layout.fluxTags).forEach(tagGroup => {
            if (!(tagGroup in data.fluxTags)) {
                data.fluxTags[tagGroup] = Object.assign({}, new_layout.fluxTags[tagGroup]);
            }
            if (Object.keys(new_layout.dataTags).length == 0) {
                Object.values(data.links).forEach(l => l.value.tags[tagGroup] = new_layout.links[l.idLink].value.tags[tagGroup]);
            }
        });
    }
    if (mode.includes('tagData')) {
        const dataTagsNames = Object.values(data.dataTags).map(tagGroup => tagGroup.group_name);
        const layoutTagsNames = Object.values(new_layout.dataTags).map(tagGroup => tagGroup.group_name);
        if (JSON.stringify(dataTagsNames) === (JSON.stringify(layoutTagsNames))) {
            // Finds the corresponding tag group by name and apply the "dynamic" attributes
            // activate, show_legend and selected.
            Object
                .values(data.dataTags)
                .forEach(dataTag => {
                Object
                    .values(new_layout.dataTags)
                    .filter(_ => _.group_name === dataTag.group_name)
                    .forEach(_ => {
                    dataTag.activated = _.activated;
                    dataTag.show_legend = _.show_legend;
                    Object
                        .values(dataTag.tags)
                        .forEach(tag => {
                        Object
                            .values(_.tags)
                            .filter(ltag => ltag.name === tag.name)
                            .forEach(ltag => {
                            tag.selected = ltag.selected;
                        });
                    });
                });
            });
        }
        else {
            data.dataTags = JSON.parse(JSON.stringify(new_layout.dataTags));
            Object.values(data.links).forEach(l => {
                if (new_layout.links[l.idLink] == undefined) {
                    return;
                }
                l.value = JSON.parse(JSON.stringify(new_layout.links[l.idLink].value));
            });
        }
    }
    //- Sanity check
    const nodes_to_remove = Object.entries(data.nodes).filter(([, n]) => !n.idNode);
    nodes_to_remove.forEach(([key]) => delete data.nodes[key]);
    const links_to_remove = Object.entries(data.links).filter(([, l]) => !l.idLink || !data.nodes[l.idSource] || !data.nodes[l.idTarget]);
    links_to_remove.forEach(([key]) => delete data.links[key]);
    if (links_to_remove.length > 0) {
        compute_default_input_outputLinksId(data.nodes, data.links);
    }
    Object.values(data.nodes).forEach(n => {
        const newInputLinksId = [];
        n.inputLinksId.forEach(linkId => {
            if (data.links[linkId]) {
                newInputLinksId.push(linkId);
            }
        });
        n.inputLinksId = newInputLinksId;
        const newOutputLinksId = [];
        n.outputLinksId.forEach(linkId => {
            if (data.links[linkId]) {
                newOutputLinksId.push(linkId);
            }
        });
        n.outputLinksId = newOutputLinksId;
        const tags_to_remove = [];
        for (const tag in n.tags) {
            if (!(tag in data.nodeTags) && !(tag in data.levelTags)) {
                tags_to_remove.push(tag);
            }
        }
        tags_to_remove.forEach(tag => { delete n.tags[tag]; });
    });
};
export const Aggregate = (n, applicationData, agregationRef) => {
    var _a, _b, _c;
    const { data } = applicationData;
    const parent_names = [];
    const dim_names = [];
    if (((_a = n.local) === null || _a === void 0 ? void 0 : _a.local_aggregation) && ((_b = n.local) === null || _b === void 0 ? void 0 : _b.local_aggregation_group_name)) {
        agregation(applicationData, n.idNode, (_c = n.local) === null || _c === void 0 ? void 0 : _c.local_aggregation_group_name);
        return;
    }
    Object.keys(n.dimensions).forEach(dim => {
        if (dim === 'Primaire') {
            if (data.levelTags['Primaire'].activated && dim_names.indexOf(dim) === -1) {
                parent_names.push(n.idNode);
                dim_names.push(dim);
            }
        }
        else if (!data.levelTags['Primaire'].activated && n.dimensions[dim].parent_name) {
            parent_names.push(n.dimensions[dim].parent_name);
            dim_names.push(dim);
        }
    });
    if (parent_names.length === 0) {
        return;
    }
    if (parent_names.length > 1) {
        agregationRef.agregationNode.current = n;
        agregationRef.isAgregationRef.current = true;
        agregationRef.showAgregationRef.current[0][1](true);
    }
    else {
        agregation(applicationData, n.idNode, dim_names[0]);
    }
};
export const Desaggregate = (n, applicationData, link_function, agregationRef) => {
    const { data } = applicationData;
    const child_names = [];
    const dim_names = [];
    Object.values(data.nodes).forEach(n2 => {
        for (const dim in n2.dimensions) {
            if (dim === 'Primaire') {
                if (data.levelTags['Primaire'].activated && dim_names.indexOf(dim) === -1) {
                    child_names.push(n2.idNode);
                    dim_names.push(dim);
                }
            }
            else if (!data.levelTags['Primaire'].activated && n2.dimensions[dim].parent_name == n.idNode) {
                if (dim_names.indexOf(dim) === -1) {
                    child_names.push(n2.idNode);
                    dim_names.push(dim);
                }
            }
        }
        return false;
    });
    if (child_names.length === 0) {
        return;
    }
    if (child_names.length > 1) {
        agregationRef.agregationNode.current = n;
        agregationRef.isAgregationRef.current = false;
        agregationRef.showAgregationRef.current[0][1](true);
    }
    else {
        desagregation(applicationData, link_function, n.idNode, dim_names[0]);
    }
};
const getDesagregationNodes = (cur_dimension, data, node) => {
    const all_dim_desagregate_nodes = Object.values(data.nodes).filter(n => n.dimensions[cur_dimension] &&
        n.dimensions[cur_dimension].parent_name === node.idNode);
    let to_remove = [];
    //const level_tags_to_skip = [cur_dimension]
    Object.values(data.levelTags).filter(tagg => tagg.group_name !== 'Primaire' &&
        tagg.activated &&
        tagg.group_name !== cur_dimension).forEach(tagg => {
        const tags_from_grp_to_display = Object.values(tagg.tags).filter(t => t.selected).map(t => t.name);
        let node_tags_attr = undefined;
        let all_same = true;
        let tagg_to_remove = [];
        all_dim_desagregate_nodes.filter(n => {
            if (node_tags_attr && n.tags[tagg.group_name] && JSON.stringify(node_tags_attr) !== JSON.stringify(n.tags[tagg.group_name])) {
                all_same = false;
            }
            node_tags_attr = n.tags[tagg.group_name];
            if (node_tags_attr === undefined) {
                return;
            }
            if (node_tags_attr.filter(t => tags_from_grp_to_display.includes(t)).length == 0) {
                tagg_to_remove.push(n);
            }
        });
        if (all_same) {
            tagg_to_remove = [];
        }
        to_remove = [...new Set([...to_remove, ...tagg_to_remove])];
    });
    const dim_desagregate_nodes = all_dim_desagregate_nodes.filter(n => !to_remove.includes(n));
    return dim_desagregate_nodes;
};
export const ComputeParametricV = (applicationData) => {
    const { data } = applicationData;
    //Object.values(applicationData.data.nodes).forEach(n=>delete (n as unknown as {v?:string}).v )
    const columns = {};
    Object.values(applicationData.display_nodes).filter(n => NodeDisplayed(data, n) && !('Type de noeud' in n.tags && n.tags['Type de noeud'][0] == 'echange')).forEach(n => {
        if (columns[n.u]) {
            columns[n.u].push(n);
        }
        else {
            columns[n.u] = [n];
        }
    });
    Object.values(columns).forEach(column => {
        column.sort((n1, n2) => n1.y - n2.y);
        column.forEach((n, i) => {
            if (i == 0) {
                return;
            }
            const dy = n.y - column[i - 1].y - nodeHeight(column[i - 1], applicationData, GetLinkValue);
            if (dy !== 0) {
                AssignNodeLocalAttribute(n, 'dy', dy);
            }
            else {
                delete n.local.dy;
            }
        });
    });
    Object.values(columns).forEach(column => {
        column.sort((n1, n2) => n1.y - n2.y);
        let current_v = 0;
        column.forEach(n => current_v = apply_v(applicationData, n, current_v));
    });
    Object.values(columns).forEach(column => {
        column.forEach(n => apply_v_agregate(applicationData.data, n));
    });
    ArrangeTrade(applicationData, true);
    reorganize_all_input_outputLinksId(applicationData.data, applicationData.data.nodes, applicationData.data.links);
};
