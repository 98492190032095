import 'open-sankey/src/css/bootstrap.css'
import 'open-sankey/src/css/main.css'
import 'open-sankey/src/css/colors/red.css'
import 'sankeydev/src/css/style_elements_sankey.css'
import './css/react-quill.css'
import './css/Login.css'
import './css/Register.css'

import React, { FunctionComponent, MutableRefObject, useState } from 'react'
import { createRoot } from 'react-dom/client'
import * as d3 from 'd3'
import LZString from 'lz-string'
import './traduction'

import {
  SankeyData, SankeyNode, SankeyLink, applicationDataType, ComponentUpdaterType,
  LinkFunctionTypes, NodeFunctionTypes, applicationContextType, contextMenuType,
  applicationStateType, uiElementsRefType, dict_hook_ref_setter_show_dialog_componentsType,
  applicationDrawType,
  AdditionalMenusType,
  processFunctionsType
} from 'open-sankey/src/types/Types'
import { NodeTooltipsContentFType } from 'open-sankey/src/draw/types/SankeyTooltipTypes'
import { GetSankeyMinWidthAndHeightFuncType } from 'open-sankey/src/configmenus/types/SankeyUtilsTypes'
import {
  convert_data, initializeAdditionalMenus, initializeApplicationContext,
  initializeApplicationData, initializeApplicationDraw, initializeComponentUpdater,
  initializeElementSelected, initializeShowDialog, initializeContextMenu, initializeLinkFunctions,
  initializeProcessFunctions, initializeUIElementsRef, initializeReinitialization, initializeNodeFunctions,
  InstallEventsOnSVG,
  moduleDialogs, DrawAll, closeAllMenu,
  DefaultSankeyData,
  SankeyApp,
  OpenSankeyDiagramSelector,
  initializeKeyHandler,
  initializeMenuConfiguration,
  ClickSaveDiagram,
  complete_sankey_data,
  DefaultNode,
  DefaultLink,
  GetSankeyMinWidthAndHeight
} from './import/OpenSankey'
import {
  OSDevinitializeApplicationData, OSDevInitializeElementSelected,
  OSDevInitializeApplicationDraw,
  OSDevInitializeShowDialog,
  OSDevInitializeProcessFunctions,
  OSDevInitializeContextMenu,
  OSDevInitializeUIElementsRef,
  OSDevInitializeNodeFunctions,
  OSDevInitializeAdditionalMenus,
  OSDevInstallEventsOnSVG,
  OSDevDrawAll,
  OSDevInitializeReinitialization,
  OSDevInitializeApplicationContext,
  OSDevInitializeComponentUpdater,
  OSDevInitializeLinkFunctions,
  OSDevModuleDialogs,
  blockers_suite_sankey,
  SankeyDevClickSaveDiagram,
  //dev_convert_data
} from './import/SankeyDev'
import {
  OSPInitializeApplicationData, OSPInitializeApplicationContext, OSPInitializeElementSelected, OSPInitializeApplicationDraw,
  OSPInitializeShowDialog, OSPInitializeComponentUpdater, OSPInitializeReinitialization, OSPInitializeContextMenu,
  OSPInitializeUIElementsRef, OSPInitializeLinkFunctions, OSPInitializeNodeFunctions, OSPInitializeAdditionalMenus,
  OSPInstallEventsOnSVG,
  OSPDrawAll,
  OSPModuleDialogs,
  DefaultOSPStyleLink,
  OSPInitializeKeyHandler,
  OSPInitalizeSelectorDetailNodes,
  plus_convert_data,
  OSPDrawLabels,
  sankey_plus_min_width_and_height,
  OSPDiagramSelector,
  OSPNodeDragEvent
} from './import/SankeyPlus'
import {
  AFMInitializeApplicationContext, AFMInitializeElementSelected, AFMInitializeApplicationDraw, AFMInitializeShowDialog,
  AFMInitializeComponentUpdater, AFMInitializeReinitialization, AFMInitializeContextMenu, AFMInitializeUIElementsRef,
  AFMInitializeLinkFunctions, AFMInitializeAdditionalMenus, AFMinitializeApplicationData,
  AFMInstallEventsOnSVG,
  AFMDrawAll,
  AFMModuleDialogs,
  AFMInitializeNodeFunctions
} from './import/MFASankey'
import {
  OSPDataVar, OSPNode, OSPLink, OSPData, OSPApplicationDrawType,
  OSPApplicationContextType, OSPApplicationDataType, OSPComponentUpdaterType,
  OSPShowMenuComponentsType, OSPElementsSelectedType,
  OSPUiElementsRefType,
  OSPContextMenuType,
  OSPLabel,
  OSPNodeFuntionType,
  OSPLinkStyle
} from 'sankeyanimation/types/Types'

import Register from './components/Register/Register'
import Login from './components/Login/Login'
import Account from './components/UserPages/Account'
import Dashboard from './components/UserPages/Dashboard'
import { Routes } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import i18next from './traduction'
import { SuiteSankeyData, suiteApplicationContextType } from '../types/Types'
// // SankeyDev js-code
import { ConvertDataFuncType } from 'open-sankey/src/configmenus/types/SankeyConvertTypes'
import {
  SuiteDrawAll, SuiteInitializeAdditionalMenus, SuiteInitializeApplicationContext, SuiteInitializeApplicationData,
  SuiteInitializeApplicationDraw, SuiteInitializeComponentUpdater, SuiteInitializeContextMenu,
  SuiteInitializeElementSelected, SuiteInitializeKeyHandler, SuiteInitializeLinkFunctions,
  SuiteInitializeNodeFunctions, SuiteInitializeProcessFunctions, SuiteInitializeReinitialization,
  SuiteInitializeShowDialog, SuiteInitializeUIElementsRef, SuiteInstallEventsOnSVG, SuiteModuleDialogs
} from './SankeySuiteModule'
import { applicationState_devVar } from 'sankeydev/types/Types'
import { SaveDiagramOptionsType } from 'open-sankey/src/dialogs/types/SankeyPersistenceTypes'
import { ChakraProvider } from '@chakra-ui/react'
import { opensankey_theme } from 'open-sankey/dist/chakra/Theme'
import { OSPUpdateMenuConf } from 'sankeyanimation/dist/src/OSPModule'
import { PasswordResetFromMail, PasswordResetFromToken } from './components/Login/PasswordReset'
import { HashRouter } from 'react-router-dom'

declare const window: Window &
  typeof globalThis & {
    SankeyToolsStatic: boolean
    sankey: {
      filiere?: string,
      header?: string,
      has_header?: boolean,
      footer?: boolean,
      logo_width?: number,
      excel?: string,
      publish?: boolean
      logo?: string
    }
  }

window.React = React
i18next.changeLanguage(navigator.language.includes('fr') ? 'fr' : 'en')

const fetchData = {
  method: 'POST'
}

const container = document.getElementById('react-container') as Element | DocumentFragment
const root = createRoot(container)



let exemple_menu = {} as { [_: string]: JSX.Element }
let formations_menu = {} as { [_: string]: JSX.Element }

if (!window.SankeyToolsStatic) {
  // Menus are not presents in mode publish
  const path = window.location.origin
  const url = path + '/opensankey/sankey/menu_examples'
  fetch(url, fetchData).then(response => {
    response.text().then(text => {
      const json_data = JSON.parse(text)
      exemple_menu = json_data.exemples_menu
      if (Object.keys(json_data.exemples_menu['Formations']).length > 0) {
        formations_menu = Object.fromEntries(
          Object.entries(json_data.exemples_menu['Formations']['Tutoriels']).filter(d => d[0] !== 'artefacts')
        ) as { [_: string]: JSX.Element }
        delete json_data.exemples_menu['Formations']['Tutoriels']
      }
    }).catch(() => {
      exemple_menu = {}
      formations_menu = {}
    }).then(() => {
      renderPage()
    })
  })
}

const get_default_data = () => {
  const os_var = DefaultSankeyData()

  const osp_var: OSPDataVar = {
    nodes: os_var.nodes as { [x: string]: OSPNode },
    links: os_var.links as { [x: string]: OSPLink },
    is_catalog: false,
    view: [],
    current_view: 'none',
    labels: {},
    icon_catalog: {},
    style_link:os_var.style_link as { [x: string]: OSPLinkStyle },
    background_image: '',
    show_background_image: false,
    style_node: os_var.style_node
  }
  os_var.style_link['default'] = DefaultOSPStyleLink()
  Object.assign(os_var, osp_var)

  const AFM_var = {
    show_uncert: false,
    //units_names: [[]],
    tooltip_names: []
  }
  Object.assign(os_var, AFM_var)

  const Dev_var = {
    //unitary_node: [],
    //process_transfo_ref: {},
    unit_link_value_display: 'percent'
  }
  Object.assign(os_var, Dev_var)

  return os_var
}

const suite_converter = (data: SuiteSankeyData, DefaultData: () => SuiteSankeyData) => {
  plus_convert_data(data, DefaultData)
  //dev_convert_data(data)
  convert_data(data, DefaultData)
}

const SuiteRootCommponent: FunctionComponent = () => {
  const [update, set_update] = useState<boolean>(true) // useState OK

  const data = get_default_data() as SuiteSankeyData

  // If the app is in edition mode then go search the data in localStorage
  // Search if a data is stored in localStorage of the navigator
  const json_data = LZString.decompress(localStorage.getItem('data') as string) as string

  if (json_data !== null && json_data !== 'undefined' && json_data !== '') {
    const new_data = JSON.parse(json_data)
    Object.assign(data, new_data)
    suite_converter(data, get_default_data as () => SuiteSankeyData)
  }

  if (window.sankey && window.sankey.filiere) {
    Object.assign(data, window.sankey.filiere)
    complete_sankey_data(data, DefaultSankeyData, DefaultNode, DefaultLink)
    suite_converter(data, get_default_data as () => SuiteSankeyData)
  }
  // ==================
  //  CREATION VARIABLE
  // ==================
  //- login info
  //const [,set_name_user] = useState(username) // useState OK TODO check ?
  // For SankeyDev
  const [blocker_token_OSP, set_blocker_token_OSP] = useState(false) // useState OK
  const [blocker_token_SSM, set_blocker_token_SSM] = useState(false) // useState OK
  const suiteApplicationContext = SuiteInitializeApplicationContext(blocker_token_OSP,blocker_token_SSM)
  const blockers = suiteApplicationContext.has_sankeydev ? blockers_suite_sankey(
    blocker_token_OSP, set_blocker_token_OSP, blocker_token_SSM, set_blocker_token_SSM) : {}


  // let logo_application=logo
  const Application = <SankeyApp
    initial_sankey_data={data}
    get_default_data={get_default_data}
    initializeApplicationContext={
      () => {
        const _ = initializeApplicationContext() as suiteApplicationContextType
        Object.assign(_, OSPInitializeApplicationContext())
        Object.assign(_, AFMInitializeApplicationContext())
        Object.assign(_, OSDevInitializeApplicationContext())
        Object.assign(_, SuiteInitializeApplicationContext(blocker_token_OSP,blocker_token_SSM))
        if (window.SankeyToolsStatic) {
          _.has_open_sankey_plus = true
          _.has_sankey_suite = true
          _.logo = window.sankey && window.sankey.logo ? window.sankey.logo : ''
          _.logo_terriflux = 'logo_terriflux.png'
        }
        return _
      }
    }
    initializeApplicationData={
      (
        data: SankeyData,
        set_data: (_: SankeyData) => void,
        get_default_data: () => SankeyData,
        display_nodes: { [_: string]: SankeyNode },
        display_links: { [_: string]: SankeyLink }
      ) => {
        const _ = initializeApplicationData(data, set_data, get_default_data, display_nodes, display_links)
        Object.assign(_, initializeApplicationData(data, set_data, get_default_data, display_nodes, display_links))
        Object.assign(_, OSPInitializeApplicationData(data, set_data, get_default_data, display_nodes, display_links))
        Object.assign(_, AFMinitializeApplicationData(data, set_data, get_default_data, display_nodes, display_links))
        Object.assign(_, OSDevinitializeApplicationData(data, set_data, get_default_data, display_nodes, display_links))
        Object.assign(_, SuiteInitializeApplicationData(data, set_data, get_default_data, display_nodes, display_links))
        return _
      }
    }
    initializeElementSelected={
      () => {
        return {
          ...initializeElementSelected(),
          ...OSPInitializeElementSelected(),
          ...AFMInitializeElementSelected(),
          ...OSDevInitializeElementSelected(),
          ...SuiteInitializeElementSelected()
        }
      }
    }
    initializeMenuConfiguration={
      (
        applicationData,
        applicationState,
        applicationContext,
        uiElementsRef,
        dict_hook_ref_setter_show_dialog_components,
        additional_menus,
        node_function,
        link_function,
        applicationDraw,
        ComponentUpdater,
        menu_configuration_nodes,
        config_link_data,
        config_link_attr,
        contextMenu,
        ref_alt_key_pressed
      )=>{
        const menu_conf= initializeMenuConfiguration(
          applicationData,
          applicationState,
          applicationContext,uiElementsRef,
          dict_hook_ref_setter_show_dialog_components,additional_menus,node_function,link_function,
          applicationDraw,
          ComponentUpdater,
          menu_configuration_nodes,config_link_data,config_link_attr,
          contextMenu,ref_alt_key_pressed
        )
        OSPUpdateMenuConf(menu_conf,applicationData,applicationContext,uiElementsRef)
        return menu_conf

      }
    }
    initializeApplicationDraw={
      (
        applicationData: applicationDataType,
        applicationState: applicationStateType,
        contextMenu: contextMenuType,
        applicationContext: applicationContextType,
        ComponentUpdater: ComponentUpdaterType,
        uiElementsRef: uiElementsRefType,
        node_function: NodeFunctionTypes,
        link_function: LinkFunctionTypes,
        start_point: { current: number[]; },
        resizeCanvas: () => void,
        ref_alt_key_pressed?: MutableRefObject<boolean>
      ) => {
        const _ = initializeApplicationDraw(
          applicationData, applicationState, contextMenu,
          applicationContext, ComponentUpdater, uiElementsRef, node_function, link_function,
          start_point, resizeCanvas
        )
        Object.assign(_, OSPInitializeApplicationDraw(
          applicationData, applicationState, contextMenu,
          applicationContext, ComponentUpdater, uiElementsRef, node_function, link_function,
          start_point, resizeCanvas
        ));
        (_ as OSPApplicationDrawType).reDrawOSPLabels = (object_to_update: OSPLabel[]) => {
          OSPDrawLabels(
            applicationData as OSPApplicationDataType,
            applicationState as OSPElementsSelectedType,
            uiElementsRef as OSPUiElementsRefType,
            contextMenu as OSPContextMenuType,
            applicationContext as OSPApplicationContextType,
            sankey_plus_min_width_and_height,
            contextMenu.closeAllMenuContext,
            _ as unknown as OSPApplicationDrawType,
            ComponentUpdater as OSPComponentUpdaterType,
            object_to_update,
            link_function,
            start_point,
            resizeCanvas
          )
          ComponentUpdater.updateComponenSaveInCache.current(false)
        }
        OSPNodeDragEvent(
          applicationData as OSPApplicationDataType,
          applicationState as OSPElementsSelectedType,
          applicationContext as OSPApplicationContextType,
          ref_alt_key_pressed?.current ?? false,
          ComponentUpdater,
          (node_function as OSPNodeFuntionType),
          link_function,
          _ as unknown as OSPApplicationDrawType
        )
        Object.assign(_, AFMInitializeApplicationDraw(
          applicationData, applicationState, contextMenu,
          applicationContext, ComponentUpdater, uiElementsRef, node_function, link_function,
          start_point, resizeCanvas
        ))
        Object.assign(_, OSDevInitializeApplicationDraw(
          applicationData, applicationState, contextMenu,
          applicationContext, ComponentUpdater, uiElementsRef, node_function, link_function,
          start_point, resizeCanvas
        ))
        Object.assign(_, SuiteInitializeApplicationDraw(
          applicationData, applicationState, contextMenu,
          applicationContext, ComponentUpdater, uiElementsRef, node_function, link_function,
          start_point, resizeCanvas
        ))
        return _
      }
    }
    initializeShowDialog={
      () => {
        return {
          ...initializeShowDialog(),
          ...OSPInitializeShowDialog(),
          ...AFMInitializeShowDialog(),
          ...OSDevInitializeShowDialog(),
          ...SuiteInitializeShowDialog()
        }
      }
    }
    initializeComponentUpdater={
      () => {
        return {
          ...initializeComponentUpdater(),
          ...OSPInitializeComponentUpdater(),
          ...AFMInitializeComponentUpdater(),
          ...OSDevInitializeComponentUpdater(),
          ...SuiteInitializeComponentUpdater()
        }
      }
    }
    initializeReinitialization={
      (
        applicationData: applicationDataType,
        applicationState: applicationStateType,
        contextMenu: contextMenuType
      ) => {
        return (() => {
          initializeReinitialization(applicationData, applicationState, contextMenu)()
          OSPInitializeReinitialization(applicationData, applicationState, contextMenu)()
          AFMInitializeReinitialization(applicationData, applicationState, contextMenu)()
          OSDevInitializeReinitialization(applicationData, applicationState, contextMenu)()
          SuiteInitializeReinitialization(applicationData, applicationState, contextMenu)()
        })
      }
    }
    closeAllMenu={
      (
        dict_hook_ref_setter_show_dialog_components: dict_hook_ref_setter_show_dialog_componentsType,
        contextMenu: contextMenuType
      ) => {
        //TODO
        return closeAllMenu(dict_hook_ref_setter_show_dialog_components, contextMenu)
      }
    }
    initializeProcessFunctions={
      (
        dict_hook_ref_setter_show_dialog_components: dict_hook_ref_setter_show_dialog_componentsType
      ) => {
        return {
          ...initializeProcessFunctions(dict_hook_ref_setter_show_dialog_components),
          //...OSPinitializeProcessFunctions(dict_hook_ref_setter_show_dialog_components),
          //...AFMInitializeProcessFunctions(dict_hook_ref_setter_show_dialog_components),
          ...OSDevInitializeProcessFunctions(dict_hook_ref_setter_show_dialog_components),
          ...SuiteInitializeProcessFunctions(),
        }
      }
    }
    initializeContextMenu={
      () => {
        return {
          ...initializeContextMenu(),
          ...OSPInitializeContextMenu(),
          ...AFMInitializeContextMenu(),
          ...OSDevInitializeContextMenu(),
          ...SuiteInitializeContextMenu()
        }
      }
    }
    initializeUIElementsRef={
      () => {
        return {
          ...initializeUIElementsRef(),
          ...OSPInitializeUIElementsRef(),
          ...AFMInitializeUIElementsRef(),
          ...OSDevInitializeUIElementsRef(),
          ...SuiteInitializeUIElementsRef()
        }
      }
    }
    initializeLinkFunctions={
      (
        applicationData: applicationDataType,
        applicationState: applicationStateType,
        contextMenu: contextMenuType,
        applicationContext: applicationContextType,
        ComponentUpdater: ComponentUpdaterType,
        uiElementsRef: uiElementsRefType,
        dict_hook_ref_setter_show_dialog_components: dict_hook_ref_setter_show_dialog_componentsType,
        ref_alt_key_pressed: React.MutableRefObject<boolean>
      ) => {
        const _ = initializeLinkFunctions(
          applicationData, applicationState, contextMenu, applicationContext,
          ComponentUpdater, uiElementsRef, dict_hook_ref_setter_show_dialog_components, ref_alt_key_pressed
        )
        Object.assign(_, OSPInitializeLinkFunctions(
          applicationData, applicationState, contextMenu, applicationContext,
          ComponentUpdater, uiElementsRef, dict_hook_ref_setter_show_dialog_components, ref_alt_key_pressed
        ))
        Object.assign(_, AFMInitializeLinkFunctions(
          applicationData, applicationState, contextMenu, applicationContext,
          ComponentUpdater, uiElementsRef, dict_hook_ref_setter_show_dialog_components, ref_alt_key_pressed
        ))
        Object.assign(_, OSDevInitializeLinkFunctions(
          applicationData, applicationState, contextMenu, applicationContext,
          ComponentUpdater, uiElementsRef, dict_hook_ref_setter_show_dialog_components, ref_alt_key_pressed
        ))
        Object.assign(_, SuiteInitializeLinkFunctions(
          applicationData, applicationState, contextMenu, applicationContext,
          ComponentUpdater, uiElementsRef, dict_hook_ref_setter_show_dialog_components, ref_alt_key_pressed
        ))
        return _
      }
    }
    initializeNodeFunctions={
      (
        applicationData: applicationDataType,
        applicationState: applicationStateType,
        contextMenu: contextMenuType,
        applicationContext: applicationContextType,
        ComponentUpdater: ComponentUpdaterType,
        uiElementsRef: uiElementsRefType,
        resizeCanvas: (_: applicationDataType) => void,
        dict_hook_ref_setter_show_dialog_components: dict_hook_ref_setter_show_dialog_componentsType,
        ref_alt_key_pressed: React.MutableRefObject<boolean>,
        accept_simple_click: React.MutableRefObject<boolean>,
        recomputeDisplayedElement: () => void,
        link_function: LinkFunctionTypes
      ) => {
        const _ = initializeNodeFunctions(
          applicationData, applicationState, contextMenu, applicationContext, ComponentUpdater,
          uiElementsRef, resizeCanvas, dict_hook_ref_setter_show_dialog_components, ref_alt_key_pressed, accept_simple_click,
          recomputeDisplayedElement, link_function
        )
        Object.assign(_, OSPInitializeNodeFunctions(
          applicationData, applicationState, contextMenu, applicationContext, ComponentUpdater,
          uiElementsRef, resizeCanvas, dict_hook_ref_setter_show_dialog_components, ref_alt_key_pressed, accept_simple_click,
          recomputeDisplayedElement, link_function
        ))
        Object.assign(_, AFMInitializeNodeFunctions(
          applicationData,applicationState,contextMenu,applicationContext,ComponentUpdater,
          uiElementsRef,resizeCanvas,dict_hook_ref_setter_show_dialog_components,ref_alt_key_pressed,accept_simple_click,
          recomputeDisplayedElement,link_function
        ))
        Object.assign(_, OSDevInitializeNodeFunctions(
          applicationData, applicationState, contextMenu, applicationContext, ComponentUpdater,
          uiElementsRef, resizeCanvas, dict_hook_ref_setter_show_dialog_components, ref_alt_key_pressed, accept_simple_click,
          recomputeDisplayedElement, link_function
        ))
        Object.assign(_, SuiteInitializeNodeFunctions(
          applicationData, applicationState, contextMenu, applicationContext, ComponentUpdater,
          uiElementsRef, resizeCanvas, dict_hook_ref_setter_show_dialog_components, ref_alt_key_pressed, accept_simple_click,
          recomputeDisplayedElement, link_function
        ))
        return _
      }
    }
    initializeAdditionalMenus={
      (
        additionalMenus: AdditionalMenusType,
        applicationContext: applicationContextType,
        applicationData: applicationDataType,
        applicationDraw: applicationDrawType,
        ComponentUpdater: ComponentUpdaterType,
        applicationState: applicationStateType,
        uiElementsRef: uiElementsRefType,
        dict_hook_ref_setter_show_dialog_components: dict_hook_ref_setter_show_dialog_componentsType,
        node_function: NodeFunctionTypes,
        link_function: LinkFunctionTypes,
        processFunctions: processFunctionsType,
        Reinitialization: () => void,
        contextMenu
      ) => {
        SuiteInitializeAdditionalMenus(
          additionalMenus,
          applicationContext, applicationData, applicationDraw, ComponentUpdater, applicationState,
          uiElementsRef, dict_hook_ref_setter_show_dialog_components, node_function, link_function, processFunctions, Reinitialization, contextMenu
        )

        initializeAdditionalMenus(
          additionalMenus,
          applicationContext, applicationData, applicationDraw, ComponentUpdater, applicationState,
          uiElementsRef, dict_hook_ref_setter_show_dialog_components, node_function, link_function, processFunctions, Reinitialization, contextMenu
        )
        const suiteApplicationContext = SuiteInitializeApplicationContext(blocker_token_OSP,blocker_token_SSM)
        if (suiteApplicationContext.has_open_sankey_plus) {
          OSPInitializeAdditionalMenus(
            additionalMenus,
            applicationContext, applicationData, applicationDraw, ComponentUpdater, applicationState,
            uiElementsRef, dict_hook_ref_setter_show_dialog_components, node_function, link_function, processFunctions, Reinitialization, contextMenu
          )
        }
        if (suiteApplicationContext.has_sankey_suite) {
          AFMInitializeAdditionalMenus(
            additionalMenus,
            applicationContext, applicationData, applicationDraw, ComponentUpdater, applicationState,
            uiElementsRef, dict_hook_ref_setter_show_dialog_components, node_function, link_function, processFunctions, Reinitialization, contextMenu
          )
        }
        additionalMenus.formations_menu = formations_menu
        if (suiteApplicationContext.has_sankeydev) {
          additionalMenus.example_menu = exemple_menu
          OSDevInitializeAdditionalMenus(
            additionalMenus,
            applicationContext, applicationData, applicationDraw, ComponentUpdater, applicationState,
            uiElementsRef, dict_hook_ref_setter_show_dialog_components, node_function, link_function, processFunctions, Reinitialization, contextMenu
          )
        }
      }
    }
    moduleDialogs={
      (
        applicationContext: applicationContextType,
        applicationData: applicationDataType,
        applicationState: applicationStateType,
        contextMenu: contextMenuType,
        applicationDraw: applicationDrawType,
        uiElementsRef: uiElementsRefType,
        dict_hook_ref_setter_show_dialog_components: dict_hook_ref_setter_show_dialog_componentsType,
        node_function: NodeFunctionTypes,
        link_function: LinkFunctionTypes,
        ComponentUpdater: ComponentUpdaterType,
        additional_menus: AdditionalMenusType,
        menu_configuration_nodes_attributes: JSX.Element,
        reDrawLegend: () => void,
        processFunctions: processFunctionsType
      ) => {
        return [
          ...moduleDialogs(
            applicationContext,
            applicationData,
            applicationState,
            contextMenu,
            applicationDraw,
            uiElementsRef,
            dict_hook_ref_setter_show_dialog_components,
            node_function,
            link_function,
            ComponentUpdater,
            additional_menus,
            menu_configuration_nodes_attributes,
            applicationDraw.reDrawLegend,
            processFunctions
          ),
          ...OSPModuleDialogs(
            applicationContext,
            applicationData,
            applicationState,
            contextMenu,
            applicationDraw,
            uiElementsRef,
            dict_hook_ref_setter_show_dialog_components,
            node_function,
            link_function,
            ComponentUpdater,
            additional_menus,
            menu_configuration_nodes_attributes,
            applicationDraw.reDrawLegend,
            processFunctions
          ),
          ...AFMModuleDialogs(
            applicationContext,
            applicationData,
            applicationState,
            contextMenu,
            applicationDraw,
            uiElementsRef,
            dict_hook_ref_setter_show_dialog_components,
            node_function,
            link_function,
            ComponentUpdater,
            additional_menus,
            menu_configuration_nodes_attributes,
            applicationDraw.reDrawLegend,
            processFunctions
          ),
          ...OSDevModuleDialogs(
            applicationContext,
            applicationData,
            applicationState,
            contextMenu,
            applicationDraw,
            uiElementsRef,
            dict_hook_ref_setter_show_dialog_components,
            node_function,
            link_function,
            ComponentUpdater,
            additional_menus,
            menu_configuration_nodes_attributes,
            applicationDraw.reDrawLegend,
            processFunctions
          ),
          ...SuiteModuleDialogs(
            applicationContext,
            applicationData,
            applicationState,
            contextMenu,
            applicationDraw,
            uiElementsRef,
            dict_hook_ref_setter_show_dialog_components,
            node_function,
            link_function,
            ComponentUpdater,
            additional_menus,
            menu_configuration_nodes_attributes,
            applicationDraw.reDrawLegend,
            processFunctions
          )
        ]
      }
    }
    DrawAll={
      (
        contextMenu: contextMenuType,
        applicationData: applicationDataType,
        uiElementsRef: uiElementsRefType,
        applicationState: applicationStateType,
        applicationContext: applicationContextType,
        alt_key_pressed: MutableRefObject<boolean>,
        accept_simple_click: { current: boolean },
        link_function: LinkFunctionTypes,
        ComponentUpdater: ComponentUpdaterType,
        dict_hook_ref_setter_show_dialog_components: dict_hook_ref_setter_show_dialog_componentsType,
        node_function: NodeFunctionTypes,
        GetSankeyMinWidthAndHeight: GetSankeyMinWidthAndHeightFuncType,
        applicationDraw: applicationDrawType
      ) => {
        // Call the function that add links to the sankey
        d3.selectAll(' .opensankey #svg #sankey_def').remove()
        d3.select(' .opensankey #svg').append('defs').attr('id', 'sankey_def');
        [DrawAll, OSPDrawAll, AFMDrawAll, OSDevDrawAll, SuiteDrawAll].forEach(
          _ => _(
            contextMenu,
            applicationData,
            uiElementsRef,
            applicationState,
            applicationContext,
            alt_key_pressed,
            accept_simple_click,
            link_function,
            ComponentUpdater,
            dict_hook_ref_setter_show_dialog_components,
            node_function,
            GetSankeyMinWidthAndHeight,
            applicationDraw
          )
        )
      }
    }
    installEventOnSVG={
      (
        contextMenu: contextMenuType,
        applicationContext:applicationContextType,
        applicationData: applicationDataType,
        uiElementsRef: uiElementsRefType,
        applicationState: applicationStateType,
        link_function: LinkFunctionTypes,
        ComponentUpdater: ComponentUpdaterType,
        dict_hook_ref_setter_show_dialog_components: dict_hook_ref_setter_show_dialog_componentsType,
        node_function: NodeFunctionTypes,
        applicationDraw: applicationDrawType
      ) => {
        [InstallEventsOnSVG, OSPInstallEventsOnSVG, AFMInstallEventsOnSVG, OSDevInstallEventsOnSVG, SuiteInstallEventsOnSVG].forEach(
          _ => _(
            contextMenu,
            applicationContext,
            applicationData,
            uiElementsRef,
            applicationState,
            link_function,
            ComponentUpdater,
            dict_hook_ref_setter_show_dialog_components,
            node_function,
            applicationDraw
          )
        )
      }
    }
    initializeKeyHandler={
      (applicationData: applicationDataType,
        uiElementsRef: uiElementsRefType,
        contextMenu: contextMenuType,
        e: KeyboardEvent,
        applicationState: applicationStateType,
        closeAllMenu: () => void,
        ref_alt_key_pressed: MutableRefObject<boolean>,
        accept_simple_click: { current: boolean },
        link_function: LinkFunctionTypes,
        ComponentUpdater: ComponentUpdaterType,
        dict_hook_ref_setter_show_dialog_components: dict_hook_ref_setter_show_dialog_componentsType,
        applicationContext: applicationContextType,
        node_function: NodeFunctionTypes,
        applicationDraw: applicationDrawType
      ) => {
        initializeKeyHandler(
          applicationData,
          uiElementsRef,
          contextMenu,
          e,
          applicationState,
          closeAllMenu,
          ref_alt_key_pressed,
          accept_simple_click,
          link_function,
          ComponentUpdater,
          dict_hook_ref_setter_show_dialog_components,
          applicationContext,
          node_function,
          applicationDraw
        )
        OSPInitializeKeyHandler(
          applicationContext as OSPApplicationContextType,
          e,
          applicationData as OSPApplicationDataType,
          applicationState as OSPElementsSelectedType,
          dict_hook_ref_setter_show_dialog_components as OSPShowMenuComponentsType,
          (applicationDraw as OSPApplicationDrawType).reDrawOSPLabels,
          ComponentUpdater as OSPComponentUpdaterType
        )
        //AFMInitializeKeyHandler //TODO
        //OSDevInitializeKeyHandler //TODO
        SuiteInitializeKeyHandler(
          applicationData,
          uiElementsRef,
          contextMenu,
          e,
          applicationState,
          closeAllMenu,
          ref_alt_key_pressed,
          accept_simple_click,
          link_function,
          ComponentUpdater,
          dict_hook_ref_setter_show_dialog_components,
          applicationContext,
          node_function,
          applicationDraw
        )
      }
    }
    initializeDiagrammSelector={(applicationData) => {
      const ospDiagrammeSelector = OSPDiagramSelector(
        applicationData as OSPApplicationDataType
      )
      const suiteApplicationContext = SuiteInitializeApplicationContext(blocker_token_OSP,blocker_token_SSM)
      if (suiteApplicationContext.has_open_sankey_plus) {
        return ospDiagrammeSelector
      } else {
        return OpenSankeyDiagramSelector
      }
    }}
    ClickSaveDiagram={
      (
        applicationData: applicationDataType,
        data: SankeyData,
        elementsSelected: applicationStateType,
        options: SaveDiagramOptionsType
      ) => {
        const getter = elementsSelected as OSPElementsSelectedType & applicationState_devVar
        // if(view !== 'none' && has_open_sankey_plus) {
        //   // If views are saved the user is asked to save the view before saving the whole diagram.
        //   const diff = CheckCurrentViewSaved(master_data, data, master_data.current_view)
        //   if(diff.length>0 && !window.SankeyToolsStatic){
        //     set_view_not_saved(view)
        //     return
        //   }
        // }
        const { master_data } = applicationData as OSPApplicationDataType
        const OSPElementsSelected = elementsSelected as OSPElementsSelectedType
        let data_to_save = data as OSPData
        if (master_data && (master_data.view.length > 0)) {
          //if views are present there are two cases. If save only view data is saved otherwise master data is saved.
          if (OSPElementsSelected.saveViewGetter.current) {
            data_to_save.current_view = 'none'
          } else {
            data_to_save = master_data
          }
        }
        if (getter.ref_getter_beautify.current) {
          SankeyDevClickSaveDiagram(
            applicationData,
            data_to_save,
            elementsSelected,
            options
          )
        } else {
          ClickSaveDiagram(
            applicationData,
            data_to_save,
            elementsSelected,
            options
          )
        }
      }
    }
    InitalizeSelectorDetailNodes={OSPInitalizeSelectorDetailNodes}
    GetSankeyMinWidthAndHeight={suiteApplicationContext.has_open_sankey_plus ? sankey_plus_min_width_and_height : GetSankeyMinWidthAndHeight}
  />

  if (window.SankeyToolsStatic) {
    return Application
  }

  return <ChakraProvider resetCSS={false} theme={opensankey_theme}>
    <HashRouter>
      <Routes>
        <Route path='/register' element={
          <Register
          navbar_logo={suiteApplicationContext.logo_application}
          update={update}
          set_update={set_update}
          />
        }
      />
      <Route
        path='/login'
      >
        <Route
          index
          element={
            <Login
            navbar_logo={suiteApplicationContext.logo_application}
            update={update}
            set_update={set_update}
            />
          }
        />
        <Route
          path='forgot'
          element={
            <PasswordResetFromMail
            navbar_logo={suiteApplicationContext.logo_application}
            update={update}
            set_update={set_update}
            />
          }
        />
        <Route
          path='reset/:token'
          element={
            <PasswordResetFromToken
            navbar_logo={suiteApplicationContext.logo_application}
            update={update}
            set_update={set_update}
            />
          }
        />
      </Route>
      <Route path='/dashboard' element={
          <Dashboard
            navbar_logo={suiteApplicationContext.logo_application}

            exemple_menu={exemple_menu}
            update={update}
            set_update={set_update}
            suiteApplicationContext={suiteApplicationContext}
            suite_convert_data={suite_converter as ConvertDataFuncType}
            SuiteDefaultData={get_default_data}
          />}
        />
        <Route path='/account' element={
          <Account
            navbar_logo={suiteApplicationContext.logo_application}
            suiteApplicationContext={suiteApplicationContext}
            blocker_suite_sankey={blockers}
            update={update}
            set_update={set_update}

          />}
        />
        <Route path='/' element={Application} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </HashRouter>
  </ChakraProvider>
}

const renderPage = () => {
  root.render(<SuiteRootCommponent />)
}

if (window.SankeyToolsStatic) {
  renderPage()
}